<template>
  <div id="app">
    <ScaleBox
      :width="1920"
      :height="1200"
      bgc="transparent"
      :delay="100"
      :isFlat="true"
    >
        <router-view ></router-view>
    </ScaleBox>
   
  </div>
</template>

<script>
import ScaleBox from 'vue2-scale-box'

export default {
  name: 'App',
  components: {
    ScaleBox,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
