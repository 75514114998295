<template>
  <div>
    <div class="dapeng_ong" @click.stop="lxlztc" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover"  @click.stop="lxlztc">
            <div class="dapeng_ong_hover_tit">
                <span>花瓣立柱栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                该模式利用立柱结构，结合水、草炭或森林腐叶土等介质作为植物根系的基质，固定并支撑植物。花瓣形状的栽培孔设计，不仅美观，而且有利于植物均匀受光。通过精确控制营养液的成分和循环，为植物提供稳定的水肥供应。
            </span>
        </div>
    </div>
    <div class="dapeng_ong2"  @click.stop="lxlztc2" @mouseenter.stop.prevent="handleMouseEnter2" @mouseleave.stop.prevent="handleMouseLeave2">
        <div class="dapeng_ong_hover2" v-show="dapeng_ong_hover2" @click.stop="lxlztc2">
            <div class="dapeng_ong_hover_tit">
                <span>螺旋立柱栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                利用螺旋状设计的立柱，将植物垂直种植，极大提高了空间利用率。通过精确控制水肥供应，为植物提供稳定生长环境。这种栽培模式不仅美观，还能让植物均匀接受光照，促进生长。螺旋立柱栽培模式适用于多种植物，易于管理，是实现绿色生产和城市垂直农业的理想选择。
            </span>
        </div>
    </div>
    <div class="dapeng_ong3"  @click.stop="lxlztc3" @mouseenter.stop.prevent="handleMouseEnter3" @mouseleave.stop.prevent="handleMouseLeave3">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover3" @click.stop="lxlztc3">
            <div class="dapeng_ong_hover_tit">
                <span>双层错排水培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                通过双层结构设计，实现植物在垂直空间内的错排种植，极大提高了单位面积的产量。同时，水培方式使得根系直接接触营养液，易于控制和管理。这种栽培模式不仅生长速度快，产量高，而且品质优良，适合大规模生产和家庭园艺应用。
            </span>
        </div>
    </div>
    
    <div class="dapeng_ong4" @click.stop="lxlztc4" @mouseenter.stop.prevent="handleMouseEnter4" @mouseleave.stop.prevent="handleMouseLeave4">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover4" @click.stop="lxlztc4">
            <div class="dapeng_ong_hover_tit">
                <span>滚筒式栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                通过电机驱动滚筒转动，使滚筒上每排种植杯中的植物根部充分浸入营养液中，为植物提供持续且均匀的营养液供给。同时，滚筒的转动能使作物受光均匀，能促进植物的生长和发育，提高产量和品质。
            </span>
        </div>
    </div>
    <div class="dapeng_ong5"  @click.stop="lxlztc5" @mouseenter.stop.prevent="handleMouseEnter5" @mouseleave.stop.prevent="handleMouseLeave5">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover5" @click.stop="lxlztc5">
            <div class="dapeng_ong_hover_tit">
                <span>菠萝塔立柱栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                菠萝塔立柱栽培模式是一种独特且高效的种植方式。它采用形似菠萝塔的立柱结构，将植物垂直种植于其中，充分利用空间，实现高效生产。该模式结合精确的水肥管理，为植物提供稳定的生长环境，确保产量和品质。菠萝塔立柱栽培模式不仅美观，而且管理便捷。
            </span>
        </div>
    </div>
    <div class="dapeng_ong6"  @click.stop="lxlztc6" @mouseenter.stop.prevent="handleMouseEnter6" @mouseleave.stop.prevent="handleMouseLeave6">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover6" @click.stop="lxlztc6">
            <div class="dapeng_ong_hover_tit">
                <span>飞碟立柱栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                飞碟立柱栽培模式，以其独特的设计和高效性受到广泛关注。该模式利用形似飞碟的立柱结构，将植物垂直种植于其中，极大提高了空间利用率。同时，结合智能化的水肥管理系统，为植物提供精准的营养支持。飞碟立柱栽培模式不仅美观实用，而且易于管理，是实现绿色种植和高效生产的理想选择。
            </span>
        </div>
    </div>
    
    <div class="dapeng_ong7"  @click.stop="lxlztc7" @mouseenter.stop.prevent="handleMouseEnter7" @mouseleave.stop.prevent="handleMouseLeave7">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover7" @click.stop="lxlztc7">
            <div class="dapeng_ong_hover_tit">
                <span>A字架栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                利用支撑架将种植槽架起，单架可托起5排种植槽，种植槽内填充该模式利用立柱结构，结合水、草炭、椰糠等介质作为植物根系的基质，固定并支撑植物。充分利用空间资源，通过精确控制营养液供应，为植物提供稳定的生长环境，适合种植茄果类作物。
            </span>
        </div>
    </div>
    <div class="dapeng_ong8" @click.stop="lxlztc8"  @mouseenter.stop.prevent="handleMouseEnter8" @mouseleave.stop.prevent="handleMouseLeave8">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover8" @click.stop="lxlztc8">
            <div class="dapeng_ong_hover_tit">
                <span>鱼菜共生</span>
            </div>
            <span class="dapeng_ong_hover_con">
               鱼菜共生模式是一种创新的生态种植方式。它将水产养殖与水培蔬菜相结合，鱼类产生的废物为蔬菜提供营养，而蔬菜则净化水质，形成了一种循环利用的生态系统。这种模式不仅提高了资源利用效率，减少了环境污染，还使得产出的鱼和蔬菜更加健康、有机。同时，鱼菜共生模式也兼具观赏价值，为都市农业和休闲农业提供了新的发展思路。
            </span>
        </div>
    </div>
    
    <div class="dapeng_ong9"  @click.stop="lxlztc9" @mouseenter.stop.prevent="handleMouseEnter9" @mouseleave.stop.prevent="handleMouseLeave9">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover9" @click.stop="lxlztc9">
            <div class="dapeng_ong_hover_tit">
                <span>荷兰桶栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                采用固体基质固定植物根系，并通过滴灌或细流灌溉供给作物营养液。荷兰桶栽培模式具有节水抗旱、耐腐蚀、牢固耐用的特点，适用于大规模的立体农业。其优势在于能够精确控制水肥供应，提高作物产量和品质，同时减少病虫害的发生。此外，荷兰桶栽培模式还具备省时省力的特点，降低了劳动强度，改善了劳动条件。
            </span>
        </div>
    </div>
    <div class="dapeng_ong10"  @click.stop="lxlztc10" @mouseenter.stop.prevent="handleMouseEnter10" @mouseleave.stop.prevent="handleMouseLeave10">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover10" @click.stop="lxlztc10">
            <div class="dapeng_ong_hover_tit">
                <span>漏窗式栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                漏窗式栽培模式是一种兼具美观与实用性的种植方式。该模式通过设计精巧的漏窗结构，为植物提供了良好的生长环境。漏窗不仅有利于植物通风透光，还能有效防止病虫害的发生。同时，结合无土栽培技术，漏窗式栽培能够精确控制水肥供应，提高作物产量和品质。
            </span>
        </div>
    </div>
    <div class="dapeng_ong11"  @click.stop="lxlztc10" @mouseenter.stop.prevent="handleMouseEnter10" @mouseleave.stop.prevent="handleMouseLeave10">
        
    </div>
    
    <div class="dapeng_ong12"  @click.stop="lxlztc12" @mouseenter.stop.prevent="handleMouseEnter12" @mouseleave.stop.prevent="handleMouseLeave12">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover12" @click.stop="lxlztc12">
            <div class="dapeng_ong_hover_tit">
                <span>蔬菜树栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                是一种创新高产的栽培模式，通过培育新型种子，结合精准水肥控制，使单棵植株达到近乎无限生长的状态，可使单株作物高量生产，在栽培学研究和农业观光方面具有重要价值。
            </span>
        </div>
    </div>
    <div class="dapeng_ong13"  @click.stop="lxlztc13" @mouseenter.stop.prevent="handleMouseEnter13" @mouseleave.stop.prevent="handleMouseLeave13">
        <div class="dapeng_ong_hover13" v-show="dapeng_ong_hover13" @click.stop="lxlztc13">
            <div class="dapeng_ong_hover_tit">
                <span>吊挂南瓜栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
               吊挂南瓜模式是一种高效且空间利用最大化的种植方式。在这种模式下，南瓜的藤蔓被悬挂在特制的支架或吊绳上，使其能够垂直生长。这种栽培方法不仅节约了地面空间，还有助于南瓜藤蔓更好地接受阳光照射，促进果实成熟。同时，无土栽培技术的应用确保了南瓜生长所需的营养供给，提高了产量和品质。
            </span>
        </div>
    </div>
    <div class="dapeng_ong14"  @click.stop="lxlztc14" @mouseenter.stop.prevent="handleMouseEnter14" @mouseleave.stop.prevent="handleMouseLeave14">
        <div class="dapeng_ong_hover13" v-show="dapeng_ong_hover14" @click.stop="lxlztc14">
            <div class="dapeng_ong_hover_tit">
                <span>吊挂丝瓜栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
               是一种高效的种植方式。通过搭建稳固的支架，丝瓜的藤蔓得以自然悬挂，其生长方向得到合理引导，充分利用空间资源。此模式不仅有利于丝瓜藤蔓均匀接受阳光照射，促进光合作用，还能有效防止病虫害的发生。同时，结合无土栽培的精准水肥管理，吊挂丝瓜模式能够确保丝瓜的健康生长和高产丰收。
            </span>
        </div>
    </div>
    
    <!-- 第一个 -->
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">花瓣立柱栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tchuaban.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：花红圆叶苋菜</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.29亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：1280株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：30公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    该模式利用立柱结构，结合水、草炭或森林腐叶土等介质作为植物根系的基质，固定并支撑植物。花瓣形状的栽培孔设计，不仅美观，而且有利于植物均匀受光。通过精确控制营养液的成分和循环，为植物提供稳定的水肥供应。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible2"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">螺旋立柱栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tctashi.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：叶用甜菜（黄梗）</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.29亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：1920株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    利用螺旋状设计的立柱，将植物垂直种植，极大提高了空间利用率。通过精确控制水肥供应，为植物提供稳定生长环境。这种栽培模式不仅美观，还能让植物均匀接受光照，促进生长。螺旋立柱栽培模式适用于多种植物，易于管理，是实现绿色生产和城市垂直农业的理想选择。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible3"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">双排错层水培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcsccp.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：金花苦苣</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.29亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：3180株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：40公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                     通过双层结构设计，实现植物在垂直空间内的错排种植，极大提高了单位面积的产量。同时，水培方式使得根系直接接触营养液，易于控制和管理。这种栽培模式不仅生长速度快，产量高，而且品质优良，适合大规模生产和家庭园艺应用。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible4"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">滚筒式栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcguntong.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：羽衣甘蓝（红色） </span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.29亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：1920株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：30公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    通过电机驱动滚筒转动，使滚筒上每排种植杯中的植物根部充分浸入营养液中，为植物提供持续且均匀的营养液供给。同时，滚筒的转动能使作物受光均匀，能促进植物的生长和发育，提高产量和品质。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible5"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">菠萝塔立柱栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcluoxuan.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：羽衣甘蓝（白色）</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.15亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：96%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：1920株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：30公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    菠萝塔立柱栽培模式是一种独特且高效的种植方式。它采用形似菠萝塔的立柱结构，将植物垂直种植于其中，充分利用空间，实现高效生产。该模式结合精确的水肥管理，为植物提供稳定的生长环境，确保产量和品质。菠萝塔立柱栽培模式不仅美观，而且管理便捷。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible6"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">飞碟立柱水培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcfeidie.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：紫京水菜</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.29亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：1080株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：40公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    飞碟立柱栽培模式，以其独特的设计和高效性受到广泛关注。该模式利用形似飞碟的立柱结构，将植物垂直种植于其中，极大提高了空间利用率。同时，结合智能化的水肥管理系统，为植物提供精准的营养支持。飞碟立柱栽培模式不仅美观实用，而且易于管理，是实现绿色种植和高效生产的理想选择。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible7"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">A字架栽培区</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcAzijia.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：观赏辣椒</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.8亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：5360株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    利用支撑架将种植槽架起，单架可托起5排种植槽，种植槽内填充该模式利用立柱结构，结合水、草炭、椰糠等介质作为植物根系的基质，固定并支撑植物。充分利用空间资源，通过精确控制营养液供应，为植物提供稳定的生长环境，适合种植茄果类作物。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible8"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">鱼菜共生</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/ycgs.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-09-07</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：加州鲈鱼</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.33亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约2000天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：68%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：3000株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：300公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    鱼菜共生模式是一种创新的生态种植方式。它将水产养殖与水培蔬菜相结合，鱼类产生的废物为蔬菜提供营养，而蔬菜则净化水质，形成了一种循环利用的生态系统。这种模式不仅提高了资源利用效率，减少了环境污染，还使得产出的鱼和蔬菜更加健康、有机。同时，鱼菜共生模式也兼具观赏价值，为都市农业和休闲农业提供了新的发展思路。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible9"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">荷兰桶栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tchelant.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-05-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：番茄（首尔红）</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.93亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约109天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：6%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：576株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    采用固体基质固定植物根系，并通过滴灌或细流灌溉供给作物营养液。荷兰桶栽培模式具有节水抗旱、耐腐蚀、牢固耐用的特点，适用于大规模的立体农业。其优势在于能够精确控制水肥供应，提高作物产量和品质，同时减少病虫害的发生。此外，荷兰桶栽培模式还具备省时省力的特点，降低了劳动强度，改善了劳动条件。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible10"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">漏窗式栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/lcszp.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：紫冠油菜</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.5亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：95%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：5390株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                        漏窗式栽培模式是一种兼具美观与实用性的种植方式。该模式通过设计精巧的漏窗结构，为植物提供了良好的生长环境。漏窗不仅有利于植物通风透光，还能有效防止病虫害的发生。同时，结合无土栽培技术，漏窗式栽培能够精确控制水肥供应，提高作物产量和品质。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible12"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">蔬菜树栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcjuxingfanqie.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-05-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：番茄树</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.1亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约109天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：6%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：2株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    是一种创新高产的栽培模式，通过培育新型种子，结合精准水肥控制，使单棵植株达到近乎无限生长的状态，可使单株作物高量生产，在栽培学研究和农业观光方面具有重要价值。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible13"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">吊挂南瓜栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcdiaoguasg.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-01</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：贝贝南瓜</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.11亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约112天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：6%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：29株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    吊挂南瓜模式是一种高效且空间利用最大化的种植方式。在这种模式下，南瓜的藤蔓被悬挂在特制的支架或吊绳上，使其能够垂直生长。这种栽培方法不仅节约了地面空间，还有助于南瓜藤蔓更好地接受阳光照射，促进果实成熟。同时，无土栽培技术的应用确保了南瓜生长所需的营养供给，提高了产量和品质。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible14"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">吊挂丝瓜栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/tcdiaoguasg.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-08</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：翡翠丝瓜</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：0.33亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约140天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：5%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：100株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：50公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                    是一种高效的种植方式。通过搭建稳固的支架，丝瓜的藤蔓得以自然悬挂，其生长方向得到合理引导，充分利用空间资源。此模式不仅有利于丝瓜藤蔓均匀接受阳光照射，促进光合作用，还能有效防止病虫害的发生。同时，结合无土栽培的精准水肥管理，吊挂丝瓜模式能够确保丝瓜的健康生长和高产丰收。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
    
  </div>
</template>

<script>
export default {
    name: 'imgHover',
  data () {
    return {
       lxlizpialogTableVisible:false, 
       lxlizpialogTableVisible2:false,
       lxlizpialogTableVisible3:false,
       lxlizpialogTableVisible4:false,
       lxlizpialogTableVisible5:false,
       lxlizpialogTableVisible6:false,
       lxlizpialogTableVisible7:false,
       lxlizpialogTableVisible8:false,
       lxlizpialogTableVisible9:false,
       lxlizpialogTableVisible10:false,
       lxlizpialogTableVisible12:false,
       lxlizpialogTableVisible13:false,
       lxlizpialogTableVisible14:false,
       dapeng_ong_hover:false,
       dapeng_ong_hover2:false,
       dapeng_ong_hover3:false,
       dapeng_ong_hover4:false,
       dapeng_ong_hover5:false,
       dapeng_ong_hover6:false,
       dapeng_ong_hover7:false,
       dapeng_ong_hover8:false,
       dapeng_ong_hover9:false,
       dapeng_ong_hover10:false,
       dapeng_ong_hover12:false,
       dapeng_ong_hover13:false,
       dapeng_ong_hover14:false,
    };
  },
  created(){
    setTimeout(() => {
        this.dapeng_ong_hover8 = true
    }, 2000);
  },
  mounted(){
  },
  methods:{
    handleMouseEnter(e) {
        this.dapeng_ong_hover = true
        this.dapeng_ong_hover8 = false
    },
    handleMouseLeave() {
       this.dapeng_ong_hover = false
       this.dapeng_ong_hover8 = true
    },
    handleMouseEnter2(e) {
        this.dapeng_ong_hover2 = true
        this.dapeng_ong_hover8 = false
    },
    handleMouseLeave2() {
        this.dapeng_ong_hover2 = false
        this.dapeng_ong_hover8 = true
    },
    handleMouseEnter3(e) {
      this.dapeng_ong_hover3 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave3() {
      this.dapeng_ong_hover3 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter4(e) {
      this.dapeng_ong_hover4 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave4() {
      this.dapeng_ong_hover4 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter5(e) {
      this.dapeng_ong_hover5 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave5() {
      this.dapeng_ong_hover5 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter6(e) {
      this.dapeng_ong_hover6 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave6() {
      this.dapeng_ong_hover6 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter7(e) {
      this.dapeng_ong_hover7 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave7() {
      this.dapeng_ong_hover7 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter8(e) {
      this.dapeng_ong_hover8 = true
    },
    handleMouseLeave8() {
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter9(e) {
      this.dapeng_ong_hover9 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave9() {
      this.dapeng_ong_hover9 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter10(e) {
      this.dapeng_ong_hover10 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave10() {
      this.dapeng_ong_hover10 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter12(e) {
      this.dapeng_ong_hover12 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave12() {
      this.dapeng_ong_hover12 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter13(e) {
      this.dapeng_ong_hover13 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave13() {
      this.dapeng_ong_hover13 = false
      this.dapeng_ong_hover8 = true
    },
    handleMouseEnter14(e) {
      this.dapeng_ong_hover14 = true
      this.dapeng_ong_hover8 = false
    },
    handleMouseLeave14() {
      this.dapeng_ong_hover14 = false
      this.dapeng_ong_hover8 = true
    },
    lxlztc(){
        this.lxlizpialogTableVisible = true;
    },
    lxlztc2(){
        this.lxlizpialogTableVisible2 = true;
    },
    lxlztc3(){
        this.lxlizpialogTableVisible3 = true;
    },
    lxlztc4(){
        this.lxlizpialogTableVisible4 = true;
    },
    lxlztc5(){
        this.lxlizpialogTableVisible5 = true;
    },
    lxlztc6(){
        this.lxlizpialogTableVisible6 = true;
    },
    lxlztc7(){
        this.lxlizpialogTableVisible7 = true;
    },
    lxlztc8(){
        this.lxlizpialogTableVisible8 = true;
    },
    lxlztc9(){
        this.lxlizpialogTableVisible9 = true;
    },
    lxlztc10(){
        this.lxlizpialogTableVisible10 = true;
    },
    lxlztc12(){
        this.lxlizpialogTableVisible12 = true;
    },
    lxlztc13(){
        this.lxlizpialogTableVisible13 = true;
    },
    lxlztc14(){
        this.lxlizpialogTableVisible14 = true;
    },
    qcguanbi() {
        this.lxlizpialogTableVisible = false;
        this.lxlizpialogTableVisible2 = false;
        this.lxlizpialogTableVisible3 = false;
        this.lxlizpialogTableVisible4 = false;
        this.lxlizpialogTableVisible5 = false;
        this.lxlizpialogTableVisible6 = false;
        this.lxlizpialogTableVisible7 = false;
        this.lxlizpialogTableVisible8 = false;
        this.lxlizpialogTableVisible9 = false;
        this.lxlizpialogTableVisible10 = false;
        this.lxlizpialogTableVisible12 = false;
        this.lxlizpialogTableVisible13 = false;
        this.lxlizpialogTableVisible14 = false;
      },
  },
}

</script>
<style scoped>
.dapeng_ong{
    width: 7vw;
    height: 7vw;
    position: absolute;
    top: 5vw;
    left: 10%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong2{
    width: 8vw;
    height: 7vw;
    position: absolute;
    top: 12vw;
    left: 10%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong3{
    width: 8vw;
    height: 7vw;
    position: absolute;
    top: 19vw;
    left: 10%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong4{
    width: 7vw;
    height: 7vw;
    position: absolute;
    top: 5vw;
    left: 20%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong5{
    width: 8vw;
    height: 7vw;
    position: absolute;
    top: 12vw;
    left: 20%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong6{
    width: 8vw;
    height: 7vw;
    position: absolute;
    top: 19vw;
    left: 22%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong7{
    width: 16vw;
    height: 9vw;
    position: absolute;
    top: 4vw;
    left: 32%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong8{
    width: 17vw;
    height: 6vw;
    position: absolute;
    top: 14vw;
    left: 32%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong9{
    width: 17vw;
    height: 6vw;
    position: absolute;
    top: 4vw;
    right: 25%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong10{
    width: 15vw;
    height: 7vw;
    position: absolute;
    top: 11vw;
    right: 25%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong11{
    width: 9vw;
    height: 7vw;
    position: absolute;
    top: 18vw;
    right: 31%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong12{
    width: 6vw;
    height: 8vw;
    position: absolute;
    top: 6vw;
    right: 15%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong13{
    width: 11vw;
    height: 5vw;
    position: absolute;
    top: 14vw;
    right: 12%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong14{
    width: 14vw;
    height: 4vw;
    position: absolute;
    top: 20vw;
    right: 10%;
    z-index: 10;
    cursor: pointer;
}
.dapeng_ong_hover{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: -4vw;
    left: 0%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover2{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: -3vw;
    left: 0%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover3{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 15vw;
    left: 10%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover4{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 0vw;
    left: 20%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover5{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 8vw;
    left: 20%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover6{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 15vw;
    left: 20%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover7{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 1vw;
    left: 35%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover8{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: -3vw;
    left: 10%;
    z-index: 11;
    background: url(../assets/images/fish.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover9{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 0vw;
    right: 32%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover10{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 8vw;
    right: 32%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover12{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 3vw;
    right: 12%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover13{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: -5vw;
    left: 10%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover14{
    width: 10vw;
    height: 9vw;
    position: absolute;
    top: 13vw;
    right: 12%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}
.dapeng_ong_hover_tit{
    width: 60%;
    height: 1.4vw;
    line-height: 1.4vw;
    text-align: center;
    background: #01FBF6;
    color: #081632;
    margin: 0 auto;
    margin-top: 0.2vw;
    font-size: 0.7vw;
    /* transform: perspective(0.5vw) rotateX(-4deg); */
}
.dapeng_ong_hover_con{
    display: block;
    width: 80%;
    font-size: 0.7vw;
    color: #fff;
    margin: 0 auto;
    line-height: 1.2vw;
    margin-top: 0.2vw;
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3; 
    text-overflow: ellipsis; 
}
</style>
