<template>
  <div>
    <div class="dapeng_ong" @click.stop="lxlztc" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div class="dapeng_ong_hover" v-show="dapeng_ong_hover"  @click.stop="lxlztc">
            <div class="dapeng_ong_hover_tit">
                <span>移动果树栽培</span>
            </div>
            <span class="dapeng_ong_hover_con">
                温室内种植了7000棵可移动式樱桃树，通过科学的种植管理和先进的温室设施，这些樱桃树能够高效生长，产出高品质、高产量的樱桃。三号玻璃温室占地10920平米，是一座集现代化农业技术与高效管理于一体的智能温室。
            </span>
        </div>
    </div>
    
    <!-- 第一个 -->
    <el-dialog
        width="45%"
        top="25vh"
        :visible.sync="lxlizpialogTableVisible"
        :modal-append-to-body="false"
    >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
            <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
            />
            <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">移动果树栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
            </div>
            <el-row class="tz_cov_top">
                <el-col :span="12">
                    <img src="@/assets/images/yintao.jpg" alt="" class="zpmsbanner" style="height:250px" />
                </el-col>
                <el-col :span="12">
                    <div class="zpmstime">
                    <span class="zpmstime_left">预计下一批成熟期</span>
                    <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                    <span class="zpmstime_right">2024-06-12</span>
                    </div>
                    <el-row style="margin-bottom: 0px">
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">作物：樱桃</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">面积：20亩</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">周期：约58天</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">成熟度：50%</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist">
                        <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">株数：7020株</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="zpmslist" style="float: right; margin-right: 6%">
                        <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                        />
                        <span class="zpmslist_name">产量预估：350公斤</span>
                        </div>
                    </el-col>
                    </el-row>
                    <p class="tczpms_list_text">
                      温室内种植了7000棵可移动式樱桃树，通过科学的种植管理和先进的温室设施，这些樱桃树能够高效生长，产出高品质、高产量的樱桃。三号玻璃温室占地10920平米，是一座集现代化农业技术与高效管理于一体的智能温室。
                    </p>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'imgHover',
  data () {
    return {
       lxlizpialogTableVisible:false, 
       dapeng_ong_hover:false,
    };
  },
  created(){
    setTimeout(() => {
        this.dapeng_ong_hover = true
    }, 2000);
  },
  mounted(){
  },
  methods:{
    handleMouseEnter(e) {
        this.dapeng_ong_hover = true
    },
    handleMouseLeave() {
       this.dapeng_ong_hover = true
    },
    lxlztc(){
        this.lxlizpialogTableVisible = true;
    },
    qcguanbi() {
        this.lxlizpialogTableVisible = false;
      },
  },
}

</script>
<style scoped>
.dapeng_ong{
    width: 1247px;
    height: 395px;
    position: absolute;
    top: 5vw;
    left: 10%;
    z-index: 10;
    cursor: pointer;
}

.dapeng_ong_hover{
    width: 10vw;
    height: 8vw;
    position: absolute;
    top: 4vw;
    left:40%;
    z-index: 11;
    background: url(../assets/images/window.png)no-repeat;
    background-size: 100% 100%;
}

.dapeng_ong_hover_tit{
    width: 60%;
    height: 1.4vw;
    line-height: 1.4vw;
    text-align: center;
    background: #01FBF6;
    color: #081632;
    margin: 0 auto;
    margin-top: 0.2vw;
    font-size: 0.7vw;
    /* transform: perspective(0.5vw) rotateX(-4deg); */
}
.dapeng_ong_hover_con{
    display: block;
    width: 80%;
    font-size: 0.7vw;
    color: #fff;
    margin: 0 auto;
    line-height: 1.2vw;
    margin-top: 0.2vw;
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3; 
    text-overflow: ellipsis; 
}
</style>
