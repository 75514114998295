import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)


import Home from '@/pages/Home'

export default new VueRouter({
    routes:[
        {
            path:"/",
            component:Home,
            meta: {
             title: '',
             requireAuth: true  // 是否需要判断是否登录,这里是需要判断
            },
            name:"home"
        },
        // 重定向
        {
            path:'*',
            redirect:"/Home"
        }
    ]
})



