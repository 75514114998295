import requests from "./request";


  // 左侧园区信息
  export function gardenLeft(farmId,auths,greenHouseId) {
    return requests({
      url: '/api/subsystem/bigs/gardenLeft?farmId='+farmId+'&auths='+auths+'&greenHouseId='+greenHouseId,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 温室列表
  export function greenHouse(farmId,auths) {
    return requests({
      url: '/api/subsystem/bigs/greenHouse?farmId='+farmId+'&auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
   // 智能设备_监测数据_重点监控
   export function gardenBelow(farmId,greenHouseId,auths) {
    return requests({
      url: '/api/subsystem/bigs/gardenBelow?farmId='+farmId+'&greenHouseId='+greenHouseId+'&auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 公共-根据设备编号获取历史数据曲线
  export function deviceSummary(obj) {
    return requests({
      url: '/api/subsystem/bigs/deviceSummary',
      headers: {
        'X-Access-Token':window.sessionStorage.getItem("token")
      },
      method: 'post',
      data:obj
    })
  }
     //  公共-摄像头列表(视频详情)
     export function camList(farmId,greenHouseId,auths) {
      return requests({
        url: '/api/subsystem/bigs/camList?farmId='+farmId+'&groupId='+greenHouseId+'&auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
     //  公共-摄像头列表分页(视频详情)
     export function camSurvPage(farmId,greenHouseId,auths,pageNo,pageSize) {
      return requests({
        url: '/api/subsystem/bigs/camSurvPage?farmId='+farmId+'&groupId='+greenHouseId+'&auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    // 摄像头控制开始
    export function cameraStart(obj) {
      return requests({
        url: '/api/subsystem/bigs/cameraStart',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
        method: 'post',
        data:obj
      })
    }
    // 摄像头控制结束
    export function cameraStop(obj) {
      return requests({
        url: '/api/subsystem/bigs/cameraStop',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
        method: 'post',
        data:obj
      })
    }
     //  温室控制-控制类设备控制项列表
     export function controlItemList(farmId,greenHouseId,auths) {
      return requests({
        url: '/api/subsystem/bigs/controlItemListNative?farmId='+farmId+'&greenHouseId='+greenHouseId+'&auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

     //  环境监测-室内环境监测_土壤环境监测
     export function envSurvData(farmId,greenHouseId,auths) {
      return requests({
        url: '/api/subsystem/bigs/envSurvData?farmId='+farmId+'&groupId='+greenHouseId+'&auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
     //  视频监控-摄像头列表
     export function camSurvList(farmId,greenHouseId,auths) {
      return requests({
        url: '/api/subsystem/bigs/camSurvList?farmId='+farmId+'&groupId='+greenHouseId+'&auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

       //  查询物联网设备监测数据
       export function deviceSurvData(farmId,greenHouseId,auths) {
        return requests({
          url: '/api/subsystem/bigs/deviceSurvData?farmId='+farmId+'&groupId='+greenHouseId+'&auths='+auths,
          method: 'get',
          headers: {
            // 'X-Access-Token':window.sessionStorage.getItem("token")
          },
        })
      }

       //  园区概览-左侧栽培模式 
       export function leftPlant(farmId,greenHouseId,auths,pageNo,pageSize) {
        return requests({
          url: '/api/subsystem/bigs/leftPlant?farmId='+farmId+'&greenHouseId='+greenHouseId+'&auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize,
          method: 'get',
          headers: {
            // 'X-Access-Token':window.sessionStorage.getItem("token")
          },
        })
      }

       //  预警列表 
       export function alertList(auths,farmId) {
        return requests({
          url: '/api/subsystem/bigs/alertList?auths='+auths+'&farmId='+farmId,
          method: 'get',
          headers: {
            // 'X-Access-Token':window.sessionStorage.getItem("token")
          },
        })
      }

      //  开关停
      export function getAllDeviceVariable(auths,deployId) {
        return requests({
          url: '/api/subsystem/bigs/getAllDeviceVariable?auths='+auths+'&deployId='+deployId,
          method: 'get',
          headers: {
            // 'X-Access-Token':window.sessionStorage.getItem("token")
          },
        })
      }

       //  农场下所有控制柜的变量 
       export function getFarmDeviceVariable(auths,farmId,greenHouseId) {
        return requests({
          url: '/api/subsystem/bigs/getFarmDeviceVariable?auths='+auths+'&farmId='+farmId+'&greenHouseId='+greenHouseId,
          method: 'get',
          headers: {
            // 'X-Access-Token':window.sessionStorage.getItem("token")
          },
        })
      }

       // 公共-设备控制指令下发
    export function cmdDevice(obj) {
      return requests({
        url: '/api/subsystem/bigs/cmdDevice',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
        method: 'post',
        data:obj
      })
    }

        // 公共-设备操作日志
        export function deviceOprateLog(auths,deployId,variableId,pageNo,pageSize) {
          return requests({
            url: '/api/subsystem/bigs/deviceOprateLog?auths='+auths+'&deployId='+deployId+'&variableId='+variableId+'&pageNo='+pageNo+'&pageSize='+pageSize,
            headers: {
              // 'X-Access-Token':window.sessionStorage.getItem("token")
            },
            method: 'get',
          })
        }

  //  农场下所有控制柜的变量 
  export function IrrigateFastStatus(auths,greenHouseId) {
    return requests({
      url: '/api/subsystem/bigs/IrrigateFastStatusNative?auths='+auths+'&greenHouseId='+greenHouseId,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }