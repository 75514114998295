import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'//样式文件需要单独引入
Vue.use(ElementUI)

// 点击全屏（不是默认的全屏，页面用的是ScaleBox）
import fullscreen from 'vue-fullscreen';
 
Vue.use(fullscreen);

// 动态加载数据
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 引入路由
import router from '@/router'

// 引入动态样式
import 'animate.css';

// main.js中
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)



// 图片预览放大
import Viewer from 'v-viewer'  //点击图片大图预览
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({  //默认样式，可以按需求更改
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

// 列表滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)


import EZUIKit from 'ezuikit-js';

import imghover from './components/imghover.vue'
Vue.component("imghoverList",imghover)
import imghoverthree from './components/imghoverthree.vue'
Vue.component("imghoverthreeList",imghoverthree)

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts;


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 注册路由
  router
}).$mount('#app')
