<template>
  <div 
  v-loading="loadingHome"
  element-loading-spinner="el-loading-spinner"
  element-loading-text=""
  element-loading-background="rgba(0, 0, 0, 0.6)"
  style="width: 100%; height: 100%;"
  >
      <!-- <img src="@/assets/images/back.jpg" alt="" style="width:100%;height:100%;resize:both; position: absolute;z-index:0;top:0px;left: 0%;"> -->
      <video class="fullscreenvideo" poster="" id="bgvid" playsinline="" autoplay="" muted="" loop="" style=" width: 100%; height: 100%; resize: both; position: absolute;z-index: 0;top: 0px; left: 0%;">
        <source src="@/assets/images/back.mp4" type="video/mp4" />
      </video>
      <img src="@/assets/images/tabadd.png" alt="" class="tabadd" @click="overclose" />
      <!-- 积温积雨统计 -->
      <div class="jiwen" @click="jwjytjtc">
        <img src="@/assets/images/02airhumidity.png" alt="" class="jiwenjiyuimg" />
        积温积雨统计
      </div>
      <div class="smartshow">
        <!-- <hefentianqi style="float: left; width: 18%; margin-top: 0.18vw" /> -->
        <el-button type="text" @click="dialogVisible = true" style="float: left; width: 22%; margin-top:0px;padding-top:0px;">
          <iframe width="210" scrolling="no" height="60" frameborder="0" allowtransparency="true" style="pointer-events: none;" src="https://i.tianqi.com?c=code&id=12&color=%23FFFFFF&icon=1&py=yuxian&num=1&site=12"></iframe>
        </el-button>
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="70%"
          top="15vh"
          :modal-append-to-body="false"
          :before-close="handleClose">
         <div class="tz_cov_chanye" style="height: 900px;position: relative;overflow-y:scroll" @scroll="handleScroll">
            <div style="position: absolute;width:100%;height:185px;background:#2681C5;">
               <img src="@/assets/images/tianqi.jpg" alt=""  />
            </div>
            <div class="lockAll" @click="lockAlls">
                更多天气 >>
            </div>
            <iframe frameborder="0" allowtransparency="true" @load="fixIframeScrollPosition" ref="iframe112" style="width:100%;height:2600px;" src="http://www.nmc.cn/publish/forecast/ASX/yuxian.html"></iframe>
         </div>
        </el-dialog>
        <span class="btn_left_time" style="margin-left: 5%">{{ nowTime }}</span>
        <span class="myselfname">
          <img src="@/assets/images/myself.png" alt="" class="myself" />
          {{ farminfo.farmName }}
        </span>
      </div>
      <el-tabs type="border-card" v-model="activeName" class="tablist" @tab-click="handleClick" v-show="tabListShow" >
        <el-tab-pane label="园区概览" name="first"></el-tab-pane>
        <el-tab-pane label="环境监测" name="second"></el-tab-pane>
        <el-tab-pane label="温室控制" name="third"></el-tab-pane>
        <el-tab-pane label="视频监控" name="fourth"></el-tab-pane>
      </el-tabs>
      <transition
        enter-active-class="animate__animated animate__jackInTheBox"
        leave-active-class="animate__animated animate__backOutRight"
        mode="out-in"
      >
        <div v-if="tabListShow == false" class="wenshifix">
          <transition
            enter-active-class="animate__animated  animate__backInLeft"
            leave-active-class="animate__animated animate__backOutRight"
            mode="out-in"
          >
            <img
              src="@/assets/images/cov3D1.png"
              alt=""
              v-if="wsimg == 'yhws'"
              key="1"
              class="jzzzright_3d"
              @click="lxlztc"
            />
            <img
              src="@/assets/images/img3d2.png"
              alt=""
              v-else-if="wsimg == 'ehws'"
              key="2"
              class="jzzzright_3d"
              @click="lxlztc"
            />
            <img
              src="@/assets/images/img3d3.png"
              alt=""
              v-else
              key="3"
              class="jzzzright_3d"
              @click="lxlztc"
            />
          </transition>
          <imghoverList v-if="wsimg == 'yhws'"  style="position: absolute;top: 3vw;left:0;width:100%;" ></imghoverList>
          <imghoverthreeList v-else  style="position: absolute;top: 3vw;left:0;width:100%;" ></imghoverthreeList>
        </div>
      </transition>
      <!-- 温室切换 -->
      <el-tabs :tab-position="tabPosition" @tab-click="handleClickwslif"  type="border-card" class="tablist1">
        <el-tab-pane v-for="item in greenHouseList" :key="item.id" :label="item.groupName" :id="item.id"></el-tab-pane>
      </el-tabs>
      <!-- 园区概览 -->
      <div style=" width: 98%; height: 90%; resize: both; position: absolute;z-index: 2; top: 8%; left: 1%; " v-show="homelist == 'yqgl'" >
        <div style="float: left; width: 21%; height: 100%">
          <transition
            enter-active-class="animate__animated animate__backInDown"
            leave-active-class="animate__animated animate__rotateOut"
            mode="out-in"
          >
            <div class="yqjscov" v-if="homelist == 'yqgl'">
              <div class="yqjscov_tit">
                <img src="@/assets/images/yqjsicon.png" alt="" class="yqjscov_tit_icon" />
                <img src="@/assets/images/yqjstxt.png" alt="" class="yqjscov_tit_yqjstxt"/>
                <img src="@/assets/images/yqjsin.png" alt="" class="yqjscov_tit_yqjsin"/>
                <span class="yqjscov_tit_show" @click="zdinfor">详情 》</span>
              </div>
              <vue-seamless-scroll :data="listData" class="yqjscov_show" :class-option="optionSetting">
                <p v-for="(item, index) in listData" :key="index">
                  {{ item }}
                </p>
              </vue-seamless-scroll>

              <el-row :gutter="15" class="yqjscov_gn" v-if="farminfo">
                <el-col :span="8">
                  <img
                    src="@/assets/images/icon_chengshi.png"
                    alt=""
                    class="animate__animated animate__heartBeat animate__slower 5s animate__infinite infinite yqjscov_gn_icon"
                  />
                  <span class="yqjscov_gn_tit">园区总占地</span>
                  <span class="yqjscov_gn_num">
                    <animate-number
                      ref="myNum"
                      from="0"
                      :to="farmAcre"
                      :key="farmAcre"
                      duration="3000"
                      :formatter="formatter"
                    ></animate-number>
                    <span style="font-size: 14px">{{ farminfo.farmAcreUnit_dictText}}</span>
                  </span>
                </el-col>
                <el-col :span="8">
                  <img
                    src="@/assets/images/icon_yuanqu.png"
                    alt=""
                    class="animate__animated animate__heartBeat animate__slower 5s animate__infinite infinite yqjscov_gn_icon"
                  />
                  <span class="yqjscov_gn_tit">预计产量预估</span>
                  <span class="yqjscov_gn_num"
                    ><animate-number
                      ref="myNum"
                      from="0"
                      :to="predictOutput"
                      :key="predictOutput"
                      duration="3000"
                      :formatter="formatter"
                    ></animate-number>
                    <span style="font-size: 14px">{{
                      farminfo.predictOutputUnit
                    }}</span>
                  </span>
                </el-col>
                <el-col :span="8">
                  <img
                    src="@/assets/images/icon_shouru.png"
                    alt=""
                    class="animate__animated animate__heartBeat animate__slower 5s animate__infinite infinite yqjscov_gn_icon"
                  />
                  <span class="yqjscov_gn_tit">预计总收入</span>
                  <span class="yqjscov_gn_num"
                    ><animate-number
                      ref="myNum"
                      from="0"
                      :to="predictProfit"
                      :key="predictProfit"
                      duration="3000"
                      :formatter="formatter"
                    ></animate-number>
                    <span style="font-size: 14px">{{
                      farminfo.predictProfitUnit
                    }}</span>
                  </span>
                </el-col>
              </el-row>
            </div>
          </transition>
          <transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__rotateOut" mode="out-in">
            <div class="zpmscov" v-if="homelist == 'yqgl'">
              <div class="yqjscov_tit" style="margin-top: 0.5vw">
                <img src="@/assets/images/zpmsicon.png" alt="" class="yqjscov_tit_icon" />
                <img src="@/assets/images/zpmstxt.png" alt="" class="yqjscov_tit_yqjstxt"/>
                <img src="@/assets/images/zpmseng.png" alt="" class="yqjscov_tit_yqjsin" />
                <span class="yqjscov_tit_show" @click="zpmsfor">详情 》</span>
              </div>
              <el-carousel :interval="5000" arrow="always" height="500px" :autoplay="true" indicator-position="none">
                <el-carousel-item v-for="(item, index) in zpmslist" :key="index">
                  <div class="zpmslbtxt">
                    <!-- @click="zpshow" -->
                    {{ item.landName }}
                  </div>
                  <viewer :images="zpmslist">
                    <img :src="urlimg + item.sceneImg" v-if="item.sceneImg" alt="" class="zpmsbanner"/>
                    <img :src="urlimg + item.landImg" v-else alt="" class="zpmsbanner"/>
                  </viewer>
                  <!-- <div v-for="(val,index) in item.cropImgs" :key="index" class="cropImgsList">
                    <img :src="urlimg + val" alt="" class="cropImgsList_img"/>
                  </div> -->
                  <el-carousel
                    height="180px"
                    :interval="5000"
                    direction="vertical"
                    :autoplay="true"
                  >
                    <el-carousel-item
                      v-for="val in item.plants"
                      :key="val.plantId"
                    >
                      <div class="zpmstime">
                        <span class="zpmstime_left">预计下一批成熟期</span>
                        <img
                          src="@/assets/images/zpmsjt.png"
                          alt=""
                          class="zpmsjt"
                        />
                        <span class="zpmstime_right">{{ val.pickMonthStr }}</span>
                      </div>
                      <el-row>
                        <el-col :span="12">
                          <div class="zpmslist">
                            <img
                              src="@/assets/images/zpmsicon1.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name">作物：{{ val.cropName }}</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div
                            class="zpmslist"
                            style="float: right; margin-right: 6%"
                          >
                            <img
                              src="@/assets/images/zpmsicon2.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name" >面积：{{ val.plantAcre }} {{ farminfo.farmAcreUnit_dictText }}</span >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="zpmslist">
                            <img
                              src="@/assets/images/zpmsicon3.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name"
                              >周期：约{{ val.growthCycle }}天</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div
                            class="zpmslist"
                            style="float: right; margin-right: 6%"
                          >
                            <img
                              src="@/assets/images/zpmsicon4.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name"
                              >成熟度：{{ val.growthPercent }}%</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="zpmslist">
                            <img
                              src="@/assets/images/zpmsicon5.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name"
                              >株数：{{ val.plantNum }}株</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div
                            class="zpmslist"
                            style="float: right; margin-right: 6%"
                          >
                            <img
                              src="@/assets/images/zpmsicon6.png"
                              alt=""
                              class="zpmsicon1"
                            />
                            <span class="zpmslist_name"
                              >产量预估：{{ val.forecastTotalOutput }}公斤</span
                            >
                          </div>
                        </el-col>
                      </el-row>
                    </el-carousel-item>
                  </el-carousel>
                </el-carousel-item>
              </el-carousel>
            </div>
          </transition>
        </div>
         <div class="dapeng">
              <transition
                enter-active-class="animate__animated  animate__backInLeft"
                leave-active-class="animate__animated animate__backOutRight"
                mode="out-in"
              >
                <img
                  src="@/assets/images/cov3D1.png"
                  alt=""
                  v-if="wsimg == 'yhws'"
                  key="1"
                  class="jzzzright_3d"
                />
                <img
                  src="@/assets/images/img3d2.png"
                  alt=""
                  v-else-if="wsimg == 'ehws'"
                  key="2"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
                <img
                  src="@/assets/images/img3d3.png"
                  alt=""
                  v-else
                  key="3"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
              </transition>
              <imghoverList v-if="wsimg == 'yhws'"></imghoverList>
              <imghoverthreeList v-else></imghoverthreeList>
          </div>
        <div class="jckjkbtn" style="background:none;">
          <el-row>
            <transition
              enter-active-class="animate__animated animate__backInUp"
              leave-active-class="animate__animated animate__rotateOut"
              mode="out-in"
            >
              <el-col :span="8" v-show="homelist == 'yqgl'">
                <div class="jzrightbtn">
                  <div class="yqjscov_tit" style="margin-top: 0.5vw">
                    <img src="@/assets/images/znsbicon.png" alt="" class="yqjscov_tit_icon" />
                    <img src="@/assets/images/znsbtxt.png" alt="" class="yqjscov_tit_yqjstxt" />
                    <img src="@/assets/images/znsbeng.png" alt="" class="yqjscov_tit_yqjsin"/>
                  </div>
                  <template v-if="soilDataList != ''">
                    <el-row :gutter="20">
                      <el-col :span="10" @click.native="sxtfor" class="shouzhiicon znsbhove" >
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon1.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">摄像头</span>
                            <span class="znsbicon1_num">
                              <animate-number ref="myNum" from="0" :to="cameraCount" :key="cameraCount" duration="3000"></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="10" :offset="2" @click.native="jcsjfor" class="shouzhiicon znsbhove">
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon2.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">土壤监测</span>
                            <span class="znsbicon1_num">
                              <animate-number ref="myNum" from="0" :to="soildeviceCount" :key="soildeviceCount" duration="3000" ></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="10" class="znsbhove shouzhiicon" @click.native="wskzgfor" >
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon3.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">温室控制柜</span>
                            <span class="znsbicon1_num" ><animate-number ref="myNum" from="0" :to="deviceCount" :key="deviceCount" duration="3000" ></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="10" class="znsbhove shouzhiicon" @click.native="jcsjfor" :offset="2">
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon4.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">气象监测</span>
                            <span class="znsbicon1_num">
                              <animate-number ref="myNum" from="0" :to="airdeviceCount" :key="airdeviceCount" duration="3000" ></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="10" class="znsbhove shouzhiicon" @click.native="sfythBox">
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon5.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">水肥一体化</span>
                            <span class="znsbicon1_num"><animate-number ref="myNum" from="0" :to="sfytdeviceCount" :key="sfytdeviceCount" duration="3000" ></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="10" class="znsbhove shouzhiicon" @click.native="jcsjfor" :offset="2" v-if="waterCount">
                        <el-row :gutter="20">
                          <el-col :span="10">
                            <img src="@/assets/images/znsbicon6.png" alt="" class="znsbicon1" />
                          </el-col>
                          <el-col :span="14">
                            <span class="znsbicon1_tit">水质监测</span>
                            <span class="znsbicon1_num">
                              <animate-number ref="myNum" from="0" :to="waterCount" :key="waterCount" duration="3000" ></animate-number>
                              <span class="znsbicon1_dan">套</span>
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </template>
                  <div v-else style="height: 500px"></div>
                </div>
              </el-col>
            </transition>
            <transition
              enter-active-class="animate__animated animate__backInUp"
              leave-active-class="animate__animated animate__rotateOut"
              mode="out-in"
            >
              <el-col :span="8" v-show="homelist == 'yqgl'">
                <div class="jzrightbtn">
                  <div class="yqjscov_tit" style="margin-top: 0.5vw">
                    <img
                      src="@/assets/images/jcsjicon.png"
                      alt=""
                      class="yqjscov_tit_icon"
                    />
                    <img
                      src="@/assets/images/jcsjtxt.png"
                      alt=""
                      class="yqjscov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/jcsjeng.png"
                      alt=""
                      class="yqjscov_tit_yqjsin"
                    />
                    <span class="yqjscov_tit_show" @click="jcsjfor"
                      >更多 》</span
                    >
                  </div>
                  <el-row :gutter="20" class="jzrightbtn2">
                      <div @click.stop="trwdgdtc($event)">
                          <vue-seamless-scroll :data="airDataListHome"  :class-option="optionSetting">
                            <el-col :span="12" class="tcgd2"  style="padding: 0px" v-for="(item, index) in airDataListHome" :key="index" :data-item="JSON.stringify(item)">
                                <div class="jcsjlist"  style="overflow: hidden" >
                                  <el-row :gutter="20">
                                    <el-col :span="10">
                                      <img :src="urlimg + item.bsIcon" alt="" class="jcsjicon1" />
                                    </el-col>
                                    <el-col :span="14">
                                      <span class="jcsjicon1_tit">{{ item.name }}   </span>
                                      <button type="button" @click="startAnimate()" style="display: none"></button>
                                      <span class="jcsjicon1_num" v-if="item.value == '0' || item.value == '0.0'|| item.value == undefined" >
                                        <span>0</span>
                                        <span class="jcsjicon1_dan">{{ item.unit }}</span>
                                      </span>
                                      <span class="jcsjicon1_num" v-else>
                                        <animate-number style="display:none;" ref="myNum1" from="0" :to="item.value" :key="item.value" duration="3000" ></animate-number>
                                        <span >{{item.value}}</span>
                                        <span class="jcsjicon1_dan">{{ item.unit }}</span>
                                      </span>
                                    </el-col>
                                  </el-row>
                                </div>
                            </el-col>
                          </vue-seamless-scroll>
                      </div>
                        
                        
                  </el-row>
                </div>
              </el-col>
            </transition>
            <transition
              enter-active-class="animate__animated animate__backInUp"
              leave-active-class="animate__animated animate__rotateOut"
              mode="out-in"
            >
              <el-col :span="8" v-show="homelist == 'yqgl'">
                <div class="jzrightbtn">
                  <div class="yqjscov_tit" style="margin-top: 0.5vw">
                    <img
                      src="@/assets/images/zdjkicon.png"
                      alt=""
                      class="yqjscov_tit_icon"
                    />
                    <img
                      src="@/assets/images/zdjktxt.png"
                      alt=""
                      class="yqjscov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/zdjkeng.png"
                      alt=""
                      class="yqjscov_tit_yqjsin"
                    />
                    <!-- 小崔 -->
                    <span class="yqjscov_tit_show" @click="sxtfor">更多 》</span>
                  </div>
                  <div style="padding: 5% 3%; position: relative;height:340px;" >
                    <el-popover
                      placement="left"
                      width="160"
                      popper-class="videoBack"
                      trigger="click"
                      v-if="deploySecondaryType=='ball'||deploySecondaryType=='halfball'"
                    >
                      <template style="position: relative">
                        <img
                          src="@/assets/images/main_control_bg.png"
                          class="jd_jkssimg"
                          alt=""
                        />
                        <i
                          class="el-icon-arrow-left sxtczlf"
                          @click="sxtcz(item.deployCode, 2)"
                        ></i>
                        <i
                          class="el-icon-arrow-right sxtczrt"
                          @click="sxtcz(item.deployCode, 3)"
                        ></i>
                        <i
                          class="el-icon-arrow-down sxtczdw"
                          @click="sxtcz(item.deployCode, 1)"
                        ></i>
                        <i
                          class="el-icon-arrow-up sxtczup"
                          @click="sxtcz(item.deployCode, 0)"
                        ></i>
                      </template>
                      <div style="position: absolut">
                        <img
                          src="@/assets/images/video_ctrl1.png"
                          class="video_ctrl1"
                          alt=""
                          @click="sxtcz(item.deployCode, 8)"
                        />
                        <img
                          src="@/assets/images/video_ctrl2.png"
                          class="video_ctrl2"
                          alt=""
                          @click="sxtcz(item.deployCode, 9)"
                        />
                        <!-- <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                              <el-button type="primary" size="mini" @click="visible = false">确定</el-button> -->
                      </div>
                      <img
                        src="@/assets/images/video02.png"
                        alt=""
                        slot="reference"
                        class="videoImg"
                      />
                    </el-popover>
                      <!-- 小崔 -->
                    <!-- <video
                      id="videoPlayer"
                      class="video-js vjs-default-skin"
                      controls
                      playsinline
                      autoplay="autoplay"
                      width="376px"
                      poster="@/assets/images/zdjkimg.png"
                      style="object-fit: cover; width: 100%"
                      
                    >
                      <source  :src="deviceUrl" type="application/x-mpegURL" />
                    </video> -->
                     <div class="video-js vjs-default-skin "  id="deployCodevideoid"></div>
                  </div>
                </div>
              </el-col>
            </transition>
          </el-row>
        </div>
      </div>
      <!-- 环境监测 -->
      <div style=" width: 98%; height: 90%;  resize: both; position: absolute;  z-index: 2; top: 8%; left: 1%; " v-show="homelist == 'hjjc'" >
        <div style="float: left; width: 21%; height: 100%">
          <transition enter-active-class="animate__animated animate__backInDown" leave-active-class="animate__animated animate__zoomOutLeft" >
            <div class="yqjscov"  v-show="homelist == 'hjjc'"  style="height: 40%" >
              <div class="yqjscov_tit">
                <img
                  src="@/assets/images/snhjjcicon.png"
                  alt=""
                  class="snhjcov_tit_icon"
                />
                <img
                  src="@/assets/images/snhjjctxt.png"
                  alt=""
                  class="snhjcov_tit_yqjstxt"
                />
                <img
                  src="@/assets/images/snhjjceng.png"
                  alt=""
                  class="snhjcov_tit_yqjsin"
                />
                <span class="yqjscov_tit_show" @click="jcsjfor">更多 》</span>
              </div>
              <el-row :gutter="20" style="margin: 0">
                <el-col :span="12" class="znsbhove" v-for="item in airDataList" :key="item.id" >
                  <div :class="item.readStatus=='normal'?'hjjccovlist':(item.readStatus=='high'?'hjjccovlistHeight':'hjjccovlistLow')" @click="trwdtc(item)">
                    <el-row>
                      <el-col :span="8">
                        <img :src="urlimg + item.bsIcon" alt="" class="hjjcicon1" />
                      </el-col>
                      <el-col :span="16">
                        <span class="hjjccovlist_tit" v-if="item.readStatus=='high'" style="color:#FF7B4D" >{{ item.name }}</span>
                        <span class="hjjccovlist_tit" v-else-if="item.readStatus=='low'" style="color:#FFF43F">{{ item.name }}</span>
                        <span class="hjjccovlist_tit" v-else>{{ item.name }}</span>
                        <span class="jcsjicon1_num" v-if="item.value == '0' || item.value == '0.0'" ><span>0</span><span class="jcsjicon1_dan">{{ item.unit }}</span>
                          <img src="@/assets/images/up.png" alt="" v-if="item.readStatus=='high'" class="updownimg" />
                          <img src="@/assets/images/down.png" alt="" v-else-if="item.readStatus=='low'" class="updownimg" />
                        </span>
                        <span class="jcsjicon1_num" v-else >
                          <animate-number ref="myNumhj1" from="0" v-if="item.readStatus=='high'" style="color:#FF7B4D" :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <animate-number ref="myNumhj1" from="0" v-else-if="item.readStatus=='low'" style="color:#FFF43F" :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <animate-number ref="myNumhj1" from="0" v-else :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <span class="jcsjicon1_dan" v-if="item.readStatus=='high'" style="color:#FF7B4D" >{{ item.unit }}</span>
                          <span class="jcsjicon1_dan" v-else-if="item.readStatus=='low'" style="color:#FFF43F" >{{ item.unit }}</span>
                          <span class="jcsjicon1_dan" v-else>{{ item.unit }}</span>
                          <img src="@/assets/images/up.png" alt="" v-if="item.readStatus=='high'" class="updownimg" />
                          <img src="@/assets/images/down.png" alt="" v-else-if="item.readStatus=='low'" class="updownimg" />
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </transition>
          <transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__rotateOut" >
            <div class="trhjjccov" v-show="homelist == 'hjjc'" style="height: 57%">
              <div class="yqjscov_tit" style="margin-top: 0.5vw">
                <img src="@/assets/images/trhjjcicon.png" alt="" class="snhjcov_tit_icon"/>
                <img src="@/assets/images/trhjjctxt.png" alt="" class="snhjcov_tit_yqjstxt" />
                <img src="@/assets/images/trhjjceng.png" alt="" class="snhjcov_tit_yqjsin" />
                <span class="yqjscov_tit_show" @click="jcsjfor">更多 》</span>
              </div>
              <el-row :gutter="20" class="trhjjccovList" style="margin: 0">
                <el-col
                  :span="12"
                  class="znsbhove"
                  v-for="item in hjsoilDataList"
                  :key="item.id"
                >
                  <div :class="item.readStatus=='normal'?'hjjccovlist':(item.readStatus=='high'?'hjjccovlistHeight':'hjjccovlistLow')" @click="trwdtc(item)">
                    <el-row>
                      <el-col :span="8">
                        <img :src="urlimg + item.bsIcon" alt="" class="hjjcicon1" />
                      </el-col>
                      <el-col :span="16">
                        <span class="hjjccovlist_tit" v-if="item.readStatus=='high'" style="color:#FF7B4D">{{ item.name }}</span>
                        <span class="hjjccovlist_tit" v-else-if="item.readStatus=='low'" style="color:#FFF43F">{{ item.name }}</span>
                        <span class="hjjccovlist_tit" v-else>{{ item.name }}</span>
                        <span class="jcsjicon1_num"  v-if="item.value == '0' || item.value == '0.0'" ><span>0</span>
                          <span class="jcsjicon1_dan">{{ item.unit }}</span>
                          <img src="@/assets/images/up.png" alt="" v-if="item.readStatus=='high'" class="updownimg" />
                          <img src="@/assets/images/down.png" alt="" v-else-if="item.readStatus=='low'" class="updownimg" />
                        </span>
                        <span class="jcsjicon1_num" v-else>
                          <animate-number ref="myNumtj1" from="0" v-if="item.readStatus=='high'" style="color:#FF7B4D" :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <animate-number ref="myNumtj1" from="0" v-else-if="item.readStatus=='low'" style="color:#FFF43F" :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <animate-number ref="myNumtj1" from="0" v-else :to="item.value" :key="item.value" duration="3000" :formatter="formatter"></animate-number>
                          <span class="jcsjicon1_dan" v-if="item.readStatus=='high'" style="color:#FF7B4D">{{ item.unit }}</span>
                          <span class="jcsjicon1_dan" v-else-if="item.readStatus=='low'" style="color:#FFF43F">{{ item.unit }}</span>
                          <span class="jcsjicon1_dan" v-else>{{ item.unit }}</span>
                          <img src="@/assets/images/up.png" alt="" v-if="item.readStatus=='high'" class="updownimg" />
                          <img src="@/assets/images/down.png" alt="" v-else-if="item.readStatus=='low'" class="updownimg" />
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </transition>
        </div>
        <div class="dapeng">
              <transition
                enter-active-class="animate__animated  animate__backInLeft"
                leave-active-class="animate__animated animate__backOutRight"
                mode="out-in"
              >
                <img
                  src="@/assets/images/cov3D1.png"
                  alt=""
                  v-if="wsimg == 'yhws'"
                  key="1"
                  class="jzzzright_3d"
                />
                <img
                  src="@/assets/images/img3d2.png"
                  alt=""
                  v-else-if="wsimg == 'ehws'"
                  key="2"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
                <img
                  src="@/assets/images/img3d3.png"
                  alt=""
                  v-else
                  key="3"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
              </transition>
              <imghoverList v-if="wsimg == 'yhws'"></imghoverList>
              <imghoverthreeList v-else></imghoverthreeList>
          </div>
        <div class="jckjkbtn" style="background:none;">
          <el-row >
            <transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__rotateOut">
              <el-col :span="12" v-show="homelist == 'hjjc'">
                <div class="jzrightbtn">
                  <div class="yqjscov_tit" style="margin-top: 0.5vw; width: 98%">
                    <img src="@/assets/images/bchyjicon.png" alt="" class="bchyjcov_tit_icon"/>
                    <img src="@/assets/images/bchyjtxt.png" alt="" style="width:4.2vw" class="bchyjcov_tit_yqjstxt" />
                    <img src="@/assets/images/bchyjeng.png" alt="" class="bchyjcov_tit_yqjsin" />
                    <!-- <span class="bchyjcov_tit_show" @click="jcsjfor">详情 》</span> -->
                  </div>
                  <div class="yujList">
                    <span class="yujList_one"> 设备名称 </span>
                    <span class="yujList_one" style="width:30%"> 预警时间 </span>
                    <span class="yujList_one"> 预警项 </span>
                     <span class="yujList_one"> 正常值 </span>
                      <span class="yujList_one" style="width:10%;"> 监测值 </span>
                  </div>

                  <ul 
                    style="width: 100%;height: 12vw;overflow: hidden; display: block; " 
                    v-loading="loading"
                    element-loading-spinner="el-loading-spinner"
                    element-loading-text=""
                    element-loading-background="rgba(0, 0, 0, 0)"
                  >
                    <vue-seamless-scroll :data="warningList" :class-option="optionSetting">
                      <li class="yujShow" v-for="item in warningList" :key="item.id">
                        <span class="yujShow_one">{{ item.deviceName }}</span>
                        <span class="yujShow_one" style="width:30%"> {{ item.alertTime }}</span>
                        <span class="yujShow_one">{{ item.itemName }}</span>
                        <span class="yujShow_one"> {{ item.normalValue}}{{item.alertContent[0].unit}}</span>
                        <span class="yujShow_one" style="width:10%;" :class="item.readStatus=='high'?'yujShow_oneheight':'yujShow_onelow'"> 
                          {{ item.survValue }}{{item.alertContent[0].unit}}
                          <img src="@/assets/images/up.png" alt="" v-if="item.readStatus=='high'" class="updownimgjcz" />
                          <img src="@/assets/images/down.png" alt="" v-else-if="item.readStatus=='low'" class="updownimgjcz" />
                        </span>
                      </li>
                    </vue-seamless-scroll>
                  </ul>
                </div>
              </el-col>
            </transition>
            <transition
              enter-active-class="animate__animated animate__backInUp"
              leave-active-class="animate__animated animate__rotateOut"
            >
              <el-col :span="12" v-show="homelist == 'hjjc'">
                <div class="jzrightbtn">
                  <div
                    class="yqjscov_tit"
                    style="margin-top: 0.5vw; width: 98%"
                  >
                    <img
                      src="@/assets/images/sfyticon.png"
                      alt=""
                      class="bchyjcov_tit_icon"
                    />
                    <img
                      src="@/assets/images/sfyttxt.png"
                      alt=""
                      class="bchyjcov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/sfyteng.png"
                      alt=""
                      class="bchyjcov_tit_yqjsin"
                    />
                    <span style="font-size:14px;color:#fff;float:right;margin-top:1.4vw;cursor:pointer;" @click="sfythBox">更多》</span>
                  </div>
                  <div class="sfythCov" >
                    <!-- v-if="level1List" -->
                    <div>
                        <img src="@/assets/images/waterfeiji.png" alt="" class="waterfeiji"/>
                        <div class="sfythCov_lft">
                          <span class="sfythCov_lft_tit" v-for="run in runStatusOverList" :key="run.id">
                            {{run.prop_name}}
                            <span class="sfythCov_lft_num">{{run.pval}} 
                              <span style="font-size:0.8vw;" v-show="run.prop_name=='灌溉计时'">s/0分</span> 
                            </span> 
                            <span style="position: absolute;top:12px;right:-15%;font-size: 0.8vw;" v-if="run.pval">状态：在线</span>
                            <span style="position: absolute;top:12px;right:-15%;font-size: 0.8vw;"  v-else>状态：离线</span>
                          </span>
                        </div>
                        <div class="sfythCov_opendow">
                            <span class="sfythCov_lft_opdwtit"><span class="sfythCov_yuand"></span> 使用中 </span>
                            <span class="sfythCov_lft_opdwtit"><span class="sfythCov_yuandwat"></span> 已关闭 </span>
                        </div>
                        <div class="sfythCov_sfei">
                            <div class="sfythCov_sfei_feilit" v-for="item in level2List" :key="item.id">
                                <span class="sfythCov_sfei_feilit_tit">{{item.prop_name}}</span>
                                <img src="@/assets/images/open01.png" v-if="item.pval=='1'" alt="" class="sfythCov_sfei_feilit_ioen"  />
                                <img src="@/assets/images/close01.png" v-else alt="" class="sfythCov_sfei_feilit_ioen"  />
                            </div>
                        </div>
                        <div class="sfythCov_fa">
                            <div class="sfythCov_sfei_falit" v-for="level3 in level3List" :key="level3.id">
                                <span class="sfythCov_sfei_feilit_tit">{{level3.prop_name}}</span>
                                <img src="@/assets/images/close04.png" v-if="level3.pval=='0'" alt="" class="sfythCov_sfei_falit_ioen"  />
                                <img src="@/assets/images/open04.png" v-else alt="" class="sfythCov_sfei_falit_ioen"  />
                            </div>
                        </div>
                        <div class="sfythCov_fa2">
                            <div class="sfythCov_sfei_falit" v-for="level4 in level4List" :key="level4.id">
                                <img src="@/assets/images/close04.png" v-if="level4.pval=='0'" alt="" class="sfythCov_sfei_falit_ioen"  />
                                <img src="@/assets/images/open04.png" v-else alt="" class="sfythCov_sfei_falit_ioen"  />
                                <span class="sfythCov_sfei_feilit_tit">{{level4.prop_name}}</span>
                            </div>
                        </div>
                        <div class="sfythCov_sfb">
                          <span class="sfythCov_sfei_feilit_tit">{{sfbList.prop_name}}:<span v-if="sfbList.pval=='0'">关</span><span v-else>开</span></span>
                          <img src="@/assets/images/close02.png" v-if="sfbList.pval=='0'" alt="" class="sfythCov_sfei_sfb_ioen"  />
                          <img src="@/assets/images/open02.png" v-else alt="" class="sfythCov_sfei_sfb_ioen"  />
                        </div>
                        <div class="sfythCov_bsb">
                          <span class="sfythCov_sfei_feilit_tit">{{bsbList.prop_name}}:<span v-if="bsbList.pval=='0'">关</span><span v-else>开</span></span>
                          <img src="@/assets/images/close02.png" v-if="bsbList.pval=='0'" alt="" class="sfythCov_sfei_sfb_ioen"  />
                          <img src="@/assets/images/open02.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
                        </div>
                        <div class="sfythCov_bsfsm">
                            <span class="sfythCov_sfei_feilit_tit">{{bsfList.prop_name}}:  <span v-if="bsfList.pval=='0'">关</span><span v-else>开</span></span>
                            <img src="@/assets/images/close03.png" alt="" v-if="bsfList.pval=='0'" class="sfythCov_sfei_sfb_ioen"  />
                            <img src="@/assets/images/open03.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
                        </div>
                        <div class="sfythCov_ggfsm">
                            <span class="sfythCov_sfei_feilit_tit">{{ggfList.prop_name}}:<span v-if="ggfList.pval=='0'">关</span><span v-else>开</span></span>
                            <img src="@/assets/images/close03.png" alt="" v-if="ggfList.pval=='0'" class="sfythCov_sfei_sfb_ioen"  />
                            <img src="@/assets/images/open03.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
                        </div>
                        <span class="sfythCov_sdfm">{{sdfList.prop_name}}: <span style="color:#64FDA9;">{{sdfList.pval}}%</span> </span>
                        <div class="sfytEch" style="width:50px;height:94px;margin-top:80px;" id='chartsfyth'></div>
                    </div>
                    <!-- <img src="@/assets/images/vloading.gif" alt="" style="width:60px;height:60px;margin:0 auto;margin-top:100px" v-else>  -->
                  </div>
                </div>
              </el-col>
            </transition>
          </el-row>
        </div>
      </div>
      <!-- 温室控制 -->
      <div style="width: 98%; height: 90%; resize: both; position: absolute; z-index: 2; top: 8%; left: 1%;" v-if="homelist == 'wskz'"
        v-loading="loading"
        element-loading-spinner="el-loading-spinner"
        element-loading-text=""
        element-loading-background="rgba(0, 0, 0, 0.6)"
      >
        <div style="float: left; width: 20.5%; height: 100%">
          <transition
            enter-active-class="animate__animated animate__backInDown"
            leave-active-class="animate__animated animate__rotateOut"
          >
            <div class="wskzcov" :key="3333" v-if="homelist == 'wskz'&&wsimg != 'shws'">
              <div class="yqjscov_tit">
                <img
                  src="@/assets/images/zmxticon.png"
                  alt=""
                  class="zmxticon"
                />
                <span class="zmxttxt2">{{zmvariableList[0].systemGroupName}}</span>
                <!-- <img src="@/assets/images/zmxttxt.png" alt="" class="zmxttxt" /> -->
                <img
                  src="@/assets/images/zmxteng.png"
                  alt=""
                  class="snhjcov_tit_yqjsin"
                />
                <div class="wskz_rticon" v-if="controlItemListNmuber != ''">
                  {{ zmvariableList.length }}
                </div>
              </div>
              <el-row v-if="controlItemListNmuber != ''">
                <el-col
                  :span="12"
                  class="znsbhove"
                  v-for="item in zmvariableList"
                  :key="item.id"
                >
                  <div class="zmxtlist">
                    <el-row>
                      <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                        <img
                          src="@/assets/images/illumination01.png"
                          alt=""
                          class="illumination01"
                        />
                      </el-col>
                      <el-col :span="16" class="zmxtlist_pad">
                        <span class="jcsjicon1_tit" @click="wskztc(item.id,item.deployId)">{{item.variableDes}} </span>
                        <span class="jcsjicon1_num">
                            <el-switch
                              v-model="item.variableValue"
                              active-color="#FF9749"
                              inactive-color="#108680"
                              active-text="开"
                              inactive-text="关"
                              :active-value="1"
                              :inactive-value="0"
                              @change="swtvalueBtn(item.variableValue, item.downIotUrl,item.upIotUrl,item.variableName)"
                            >
                            </el-switch>
                            <span class="lvdian" v-if="item.variableValue>=0"></span>  <span class="huidian" v-else></span> 
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </transition>
          <transition
            enter-active-class="animate__animated animate__backInDown"
            leave-active-class="animate__animated animate__rotateOut"
          >
            <div class="wskzcovTwo" :key="55555" v-if="homelist == 'wskz'">
              <div class="yqjscov_tit" style="margin-top: 0.5vw">
                <img
                  src="@/assets/images/bwxticon.png"
                  alt=""
                  class="zmxticon"
                />
                 <span class="zmxttxt2">{{bwvariableList[0].systemGroupName}}  </span>
                <!-- <img src="@/assets/images/bwxttxt.png" alt="" class="zmxttxt" /> -->
                <img
                  src="@/assets/images/bwxteng.png"
                  alt=""
                  class="snhjcov_tit_yqjsin"
                />
                <div class="wskz_rticon" v-if="controlItemListNmuber != ''">
                  {{ bwvariableList.length }}
                </div>
              </div>
              <el-row class="bwxtcovList" style="height:90%;" v-if="controlItemListNmuber != ''">
                <el-col
                  :span="12"
                  class="znsbhove"
                  v-for="(item,index) in bwvariableList"
                  :key="index"
                >
                  <div class="bwxtlistfor">
                    <el-row>
                      <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                        <img src="@/assets/images/internalinsulationswitch.png"  alt="" class="baowenicon" />
                      </el-col>
                      <el-col :span="16">
                        <span class="bwxtlistfor_tit" @click="wskztc(item.id,item.deployId)">{{item.variableDes}}</span>
                        <span class="able_btns" :key="getTimes">
                            <div v-for="(v, indexs) in item.variableOptions.slice(0,3)" :key="indexs"
                               :class="[v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '关' ? 'off' : v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '停'? 'pause' : 'on', Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) >1||Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) ==0 ? 'actives' : v.variableValue == 1 ? 'active' : '']"
                                @click="swtvalueBtn3(v.variableValue, item.downIotUrl,item.upIotUrl,v.variableName)">
                              <span>{{ v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1)!='展'?v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1):v.variableDes.slice(v.variableDes.length-1,v.variableDes.length-0) }}</span>
                            </div>
                        </span>
                        <span class="lvdian2" v-if="item.variableValue>=0"></span>  <span class="huidian2" v-else></span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </transition>
          <transition
            enter-active-class="animate__animated animate__backInDown"
            leave-active-class="animate__animated animate__rotateOut"
          >
            <div :class="wsimg === 'shws'?'wskzcovThtree2':'wskzcovThtree' " :key="44444" v-if="homelist == 'wskz'">
              <div class="yqjscov_tit" style="margin-top: 0.5vw">
                <img
                  src="@/assets/images/tfxticon.png"
                  alt=""
                  class="snhjcov_tit_icon"
                />
                <span class="zmxttxt2">通风系统</span>
                <!-- <img src="@/assets/images/tfxttxt.png" alt="" class="zmxttxt" /> -->
                <img
                  src="@/assets/images/ttfxteng.png"
                  alt=""
                  class="snhjcov_tit_yqjsin"
                />
                <div class="wskz_rticon" v-if="controlItemListNmuber != ''">
                  {{ tfvariableList.length }}
                </div>
              </div>
              <el-row class="bwxtcovList" v-if="controlItemListNmuber != ''">
                <el-col
                  :span="12"
                  class="znsbhove"
                  v-for="(item,index) in tfvariableList"
                  :key="index"
                >
                  <div class="bwxtlistfor">
                    <el-row>
                      <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                        <img src="@/assets/images/topopeningwindowclosing.png"  alt="" class="baowenicon" />
                      </el-col>
                      <el-col :span="16">
                        <span class="bwxtlistfor_tit" @click="wskztc(item.id,item.deployId)">{{item.variableDes}}</span>
                        <span class="able_btns" :key="getTimes">
                            <div v-for="(v, indexs) in item.variableOptions.slice(0,3)" :key="indexs"
                               :class="[v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '关' ? 'off' : v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '停'? 'pause' : 'on', Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) >1||Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) ==0 ? 'actives' : v.variableValue == 1 ? 'active' : '']"
                                @click="swtvalueBtn2(v.variableValue, item.downIotUrl,item.upIotUrl,v.variableName)">
                              <span>{{ v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1)!='展'?v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1):v.variableDes.slice(v.variableDes.length-1,v.variableDes.length-0) }}</span>
                            </div>
                        </span>
                        <span class="lvdian2" v-if="item.variableValue>=0"></span>  <span class="huidian2" v-else></span>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </transition>
        </div>
        <div class="dapeng">
              <transition
                enter-active-class="animate__animated  animate__backInLeft"
                leave-active-class="animate__animated animate__backOutRight"
                mode="out-in"
              >
                <img
                  src="@/assets/images/cov3D1.png"
                  alt=""
                  v-if="wsimg == 'yhws'"
                  key="1"
                  class="jzzzright_3d"
                />
                <img
                  src="@/assets/images/img3d2.png"
                  alt=""
                  v-else-if="wsimg == 'ehws'"
                  key="2"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
                <img
                  src="@/assets/images/img3d3.png"
                  alt=""
                  v-else
                  key="3"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
              </transition>
              <imghoverList v-if="wsimg == 'yhws'"></imghoverList>
              <imghoverthreeList v-else></imghoverthreeList>
          </div>
        <transition
            enter-active-class="animate__animated animate__backInUp"
            leave-active-class="animate__animated animate__rotateOut"
          >
            <div class="jckjkbtn" :key="66666" v-if="homelist == 'wskz'">
              <div class="jckjkcov_tit" style="margin-top: 0.5vw">
                <img
                  src="@/assets/images/jwxticon.png"
                  alt=""
                  class="jckjkcov_tit_icon"
                />
                <span class="jckjkcov_tit_yqjstxt2">降温系统</span>
                <!-- <img
                  src="@/assets/images/jwxttxt.png"
                  alt=""
                  class="jckjkcov_tit_yqjstxt"
                /> -->
                <img
                  src="@/assets/images/jwxteng.png"
                  alt=""
                  class="jckjkcov_tit_yqjsin"
                />
                <!-- <div class="wskz_rticon">22</div> -->
              </div>
              <el-row :gutter="20" v-if="controlItemListNmuber != ''">
                <el-col :span="8">
                  <div class="jwxtliti">
                    <img
                      src="@/assets/images/jwleft.png"
                      alt=""
                      class="jwleft"
                    />
                    <span>内/外遮阳.{{ nwzyvariableList.length }}</span>
                    <img
                      src="@/assets/images/jwright.png"
                      alt=""
                      class="jwright"
                    />
                  </div>
                  <el-row class="shilianboxcov">
                    <el-col
                      :span="12"
                      class="znsbhove"
                      v-for="(item,index) in nwzyvariableList"
                      :key="index"
                    >
                      <div class="bwxtlistfor">
                        <el-row>
                          <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                            <img
                              src="@/assets/images/outershadegate.png"
                              alt=""
                              class="shiliantion01"
                            />
                          </el-col>
                          <el-col :span="16" class="zmxtlist_pad">
                            <span class="bwxthovlist_tit" @click="wskztc(item.id,item.deployId)">{{ item.variableDes}}</span>
                            <span class="able_btns" :key="getTimes">
                                
                                <div v-for="(v, indexs) in item.variableOptions.slice(0,3)" :key="indexs"
                                   :class="[v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '关' ? 'off' : v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1) == '停'? 'pause' : 'on', Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) >1||Number(item.variableOptions[0].variableValue) + Number(item.variableOptions[1].variableValue) + Number(item.variableOptions[2].variableValue) ==0 ? 'actives' : v.variableValue == 1 ? 'active' : '']"
                                   @click="swtvalueBtn1(v.variableValue, item.downIotUrl,item.upIotUrl,v.variableName)">
                                  <span>{{ v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1)!='展'?v.variableDes.slice(v.variableDes.length-2,v.variableDes.length-1):v.variableDes.slice(v.variableDes.length-1,v.variableDes.length-0) }}</span>
                                </div>
                            </span>
                            <span class="lvdian3" v-if="item.variableValue>=0"></span>  <span class="huidian3" v-else></span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <div class="jwxtliti">
                    <img
                      src="@/assets/images/jwleft.png"
                      alt=""
                      class="jwleft"
                    />
                    <span>风机/内循环.{{ fjzyvariableList.length }}</span>
                    <img
                      src="@/assets/images/jwright.png"
                      alt=""
                      class="jwright"
                    />
                  </div>
                  <el-row class="shilianboxcov">
                    <el-col
                      :span="12"
                      class="znsbhove"
                      v-for="item in fjzyvariableList"
                      :key="item.id"
                    >
                      <div class="zmxtlist3">
                        <el-row>
                          <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                            <img
                              src="@/assets/images/fanmachine.png"
                              alt=""
                              class="shiliantion03"
                            />
                          </el-col>
                          <el-col :span="16" class="zmxtlist_pad3">
                            <span class="jcsjicon1_tit" @click="wskztc(item.id,item.deployId)">{{item.variableDes}}</span>
                            <span class="jcsjicon1_num">
                                <el-switch
                                  v-model="item.variableValue"
                                  active-color="#FF9749"
                                  inactive-color="#108680"
                                  active-text="开"
                                  inactive-text="关"
                                  :active-value="1"
                                  :inactive-value="0"
                                  @change="swtvalueBtn(item.variableValue, item.downIotUrl,item.upIotUrl,item.variableName)"
                                >
                                </el-switch>
                                <span class="lvdian" v-if="item.variableValue>=0"></span>  <span class="huidian" v-else></span>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <div class="jwxtliti">
                    <img
                      src="@/assets/images/jwleft.png"
                      alt=""
                      class="jwleft"
                    />
                    <span>湿帘.{{ slzyvariableList.length }}</span>
                    <img
                      src="@/assets/images/jwright.png"
                      alt=""
                      class="jwright"
                    />
                  </div>
                  <el-row class="shilianboxcov">
                    <el-col :span="12" class="znsbhove" v-for="item in slzyvariableList" :key="item.id">
                      <div class="zmxtlist3">
                        <el-row>
                          <el-col :span="8" @click.native="wskztc(item.id,item.deployId)">
                            <img src="@/assets/images/waterpumpoff.png" alt="" class="shiliantion03" />
                          </el-col>
                          <el-col :span="16" class="zmxtlist_pad3">
                            <span class="jcsjicon1_tit" @click="wskztc(item.id,item.deployId)">{{item.variableDes}}</span>
                            <span class="jcsjicon1_num">
                                <el-switch
                                  v-model="item.variableValue"
                                  active-color="#FF9749"
                                  inactive-color="#108680"
                                  active-text="开"
                                  inactive-text="关"
                                  :active-value="1"
                                  :inactive-value="0"
                                  @change="swtvalueBtn(item.variableValue, item.downIotUrl,item.upIotUrl,item.variableName)"
                                >
                                </el-switch>
                                <span class="lvdian" v-if="item.variableValue>=0"></span>  <span class="huidian" v-else></span>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </transition>
      </div>
      <!-- 视频监控 -->
      <div style=" width: 98%; height: 90%; resize: both; position: absolute; z-index: 2; top: 8%; left: 1%; " v-if="homelist == 'spjk'" >
        <div style="float: left; width: 21%; height: 100%" >
          <transition enter-active-class="animate__animated animate__backInDown" leave-active-class="animate__animated animate__backOutLeft" >
            <template v-if="homelist == 'spjk'"  >
              <transition enter-active-class="animate__animated animate__backInDown" leave-active-class="animate__animated animate__backOutLeft" >
                <div class="jckjkcov" key="222" >
                  <div class="yqjscov_tit">
                    <img
                      src="@/assets/images/zpqicon.png"
                      alt=""
                      class="snhjcov_tit_icon"
                    />
                    <img
                      src="@/assets/images/zpqtxt.png"
                      alt=""
                      class="snhjcov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/zpqeng.png"
                      alt=""
                      class="snhjcov_tit_yqjsin"
                    />
                    <span class="yqjscov_tit_show" @click="sxtfor">更多 》</span>
                  </div>
                  <div
                    v-for="(item, index) in landCamList.slice(0,4)"
                    :key="index"
                    style="padding: 2%; position: relative"
                  >
                    <el-popover
                      placement="bottom"
                      width="160"
                      popper-class="videoBack"
                      trigger="click"
                       v-if="item.deploySecondaryType=='ball'||item.deploySecondaryType=='halfball'"
                    >
                      <template style="position: relative">
                        <img
                          src="@/assets/images/main_control_bg.png"
                          class="jd_jkssimg"
                          alt=""
                        />
                        <i
                          class="el-icon-arrow-left sxtczlf"
                          @click="sxtcz(item.deployCode, 2)"
                        ></i>
                        <i
                          class="el-icon-arrow-right sxtczrt"
                          @click="sxtcz(item.deployCode, 3)"
                        ></i>
                        <i
                          class="el-icon-arrow-down sxtczdw"
                          @click="sxtcz(item.deployCode, 1)"
                        ></i>
                        <i
                          class="el-icon-arrow-up sxtczup"
                          @click="sxtcz(item.deployCode, 0)"
                        ></i>
                      </template>
                      <div style="position: absolut">
                        <img
                          src="@/assets/images/video_ctrl1.png"
                          class="video_ctrl1"
                          alt=""
                          @click="sxtcz(item.deployCode, 8)"
                        />
                        <img
                          src="@/assets/images/video_ctrl2.png"
                          class="video_ctrl2"
                          alt=""
                          @click="sxtcz(item.deployCode, 9)"
                        />
                        <!-- <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                              <el-button type="primary" size="mini" @click="visible = false">确定</el-button> -->
                      </div>
                      <img
                        src="@/assets/images/video02.png"
                        alt=""
                        slot="reference"
                        class="videoImg2"
                      />
                    </el-popover>
                    <!-- <span class="jckjkList_tit">{{ item.deployDes }}</span> -->
                    <div class="video-js vjs-default-skin vjs-big-play-centered jczimg " :id="item.deployCode"></div>
                  </div>
                </div>
              </transition>
            </template>
          </transition>
        </div>
        <div class="dapeng">
              <transition
                enter-active-class="animate__animated  animate__backInLeft"
                leave-active-class="animate__animated animate__backOutRight"
                mode="out-in"
              >
                <img
                  src="@/assets/images/cov3D1.png"
                  alt=""
                  v-if="wsimg == 'yhws'"
                  key="1"
                  class="jzzzright_3d"
                />
                <img
                  src="@/assets/images/img3d2.png"
                  alt=""
                  v-else-if="wsimg == 'ehws'"
                  key="2"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
                <img
                  src="@/assets/images/img3d3.png"
                  alt=""
                  v-else
                  key="3"
                  class="jzzzright_3d"
                  @click="lxlztc"
                />
              </transition>
              <imghoverList v-if="wsimg == 'yhws'"></imghoverList>
              <imghoverthreeList v-else></imghoverthreeList>
          </div>
        <transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__backOutDown" >
            <template v-if="homelist == 'spjk'" >
              <transition enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__backOutDown" >
                <div class="jckjkbtn" key="222">
                  <div class="jckjkcov_tit" style="margin-top: 0.5vw; width: 99%" v-if="wsimg =='yhws'">
                    <img
                       src="@/assets/images/jckicon.png"
                      alt=""
                      class="jckjkcov_tit_icon"
                    />
                    <img
                      src="@/assets/images/jcktxt.png"
                      alt=""
                      class="jckjkcov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/jckeng.png"
                      alt=""
                      class="jckjkcov_tit_yqjsin"
                    />
                    <span class="jckjkcov_tit_show" @click="sxtfor">更多 》</span>
                    <!-- <span class="jckjkcov_tit_show" >详情 》</span> -->
                  </div>
                  <div class="jckjkcov_tit" style="margin-top: 0.5vw; width: 99%" v-else>
                    <img
                      src="@/assets/images/zpqicon.png"
                      alt=""
                      class="jckjkcov_tit_icon"
                    />
                    <img
                      src="@/assets/images/zpqtxt.png"
                      alt=""
                      class="jckjkcov_tit_yqjstxt"
                    />
                    <img
                      src="@/assets/images/zpqeng.png"
                      alt=""
                      class="jckjkcov_tit_yqjsin"
                    />
                    <span class="jckjkcov_tit_show" @click="sxtfor">更多 》</span>
                    <!-- <span class="jckjkcov_tit_show" >详情 》</span> -->
                  </div>
                  <el-scrollbar style="height: 96%">
                    <ul class="jckjkbtn_flx" v-if="wsimg =='yhws'">
                      <li
                        class="jckjk_right"
                        v-for="(item, index) in inoutCamList"
                        :key="index"
                        style="padding: 0.5% 0 0 0.5%; position: relative"
                      >
                        <el-popover
                          placement="bottom"
                          width="160"
                          popper-class="videoBack"
                          trigger="click"
                          :append-to-body="false"
                           v-if="item.deploySecondaryType=='ball'||item.deploySecondaryType=='halfball'"
                        >
                          <template>
                            <img
                              src="@/assets/images/main_control_bg.png"
                              class="jd_jkssimg"
                              alt=""
                            />
                            <i
                              class="el-icon-arrow-left sxtczlf"
                              @click="sxtcz(item.deployCode, 2)"
                            ></i>
                            <i
                              class="el-icon-arrow-right sxtczrt"
                              @click="sxtcz(item.deployCode, 3)"
                            ></i>
                            <i
                              class="el-icon-arrow-down sxtczdw"
                              @click="sxtcz(item.deployCode, 1)"
                            ></i>
                            <i
                              class="el-icon-arrow-up sxtczup"
                              @click="sxtcz(item.deployCode, 0)"
                            ></i>
                          </template>
                          <div style="position: absolut">
                            <img
                              src="@/assets/images/video_ctrl1.png"
                              class="video_ctrl11"
                              alt=""
                              @click="sxtcz(item.deployCode, 8)"
                            />
                            <img
                              src="@/assets/images/video_ctrl2.png"
                              class="video_ctrl22"
                              alt=""
                              @click="sxtcz(item.deployCode, 9)"
                            />
                          </div>
                          <img
                            src="@/assets/images/video02.png"
                            alt=""
                            slot="reference"
                            class="videoImg2"
                          />
                        </el-popover>
                        <div  class="video-js vjs-default-skin vjs-big-play-centered jczimg " :id="item.deployCode"></div>
                        <!-- <span class="jckjkList_tit">{{ item.deployDes }}</span> -->
                      </li>
                    </ul>
                    <ul class="jckjkbtn_flx" v-else>
                      <li
                        class="jckjk_right2"
                        v-for="(item, index) in inoutCamList"
                        :key="index"
                        style="padding: 0.5% 0 0 0.5%; position: relative;"
                      >
                        <el-popover
                          placement="bottom"
                          width="160"
                          popper-class="videoBack"
                          trigger="click"
                           v-if="item.deploySecondaryType=='ball'||item.deploySecondaryType=='halfball'"
                        >
                          <template>
                            <img
                              src="@/assets/images/main_control_bg.png"
                              class="jd_jkssimg"
                              alt=""
                            />
                            <i
                              class="el-icon-arrow-left sxtczlf"
                              @click="sxtcz(item.deployCode, 2)"
                            ></i>
                            <i
                              class="el-icon-arrow-right sxtczrt"
                              @click="sxtcz(item.deployCode, 3)"
                            ></i>
                            <i
                              class="el-icon-arrow-down sxtczdw"
                              @click="sxtcz(item.deployCode, 1)"
                            ></i>
                            <i
                              class="el-icon-arrow-up sxtczup"
                              @click="sxtcz(item.deployCode, 0)"
                            ></i>
                          </template>
                          <div style="position: absolut">
                            <img
                              src="@/assets/images/video_ctrl1.png"
                              class="video_ctrl11"
                              alt=""
                              @click="sxtcz(item.deployCode, 8)"
                            />
                            <img
                              src="@/assets/images/video_ctrl2.png"
                              class="video_ctrl22"
                              alt=""
                              @click="sxtcz(item.deployCode, 9)"
                            />
                          </div>
                          <img
                            src="@/assets/images/video02.png"
                            alt=""
                            slot="reference"
                            class="videoImg2"
                          />
                        </el-popover>
                        <div  class="video-js vjs-default-skin vjs-big-play-centered jczimg jckjk_right2Video"  :id="item.deployCode"></div>

                        <!-- <span class="jckjkList_tit">{{ item.deployDes }}</span> -->
                      </li>
                    </ul>
                  </el-scrollbar>
                </div>
              </transition>
            </template>
          </transition>
      </div>
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="qczddialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_chanye" style="height: 35vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">园区介绍</span>
          </div>
          <el-row class="tz_cov_topyqjs">
            <el-col :span="14">
              <div class="grid-content bg-purple ycboxlt">
                <el-carousel
                  indicator-position="outside"
                  :interval="2000"
                  height="540px"
                  width="100%"
                >
                  <el-carousel-item v-for="item in yqjsbanlist" :key="item.id">
                    <viewer style="width: 100%; height: 100%">
                      <img
                        :src="item.prc"
                        alt=""
                        style="width: 100%; height: 100%; margin-top: 0.5vw"
                      />
                    </viewer>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="grid-content bg-purple-light">
                <p class="tcgldw">
                  盂县智慧农业园区总占地24808平方米，其中一号棚占地6944平方米，二号棚占地6944平方米三号棚占地10920平方米。
                  一号棚：智慧农业技术体现。该温室设计主要以多种栽培模式以及现代化、智能化相结合进行规划，包括鱼菜共生、螺旋立柱栽培等栽培模式，通过智能物联网数据实时采集、水肥一体化自动化灌溉、温室自动化控制，结合AI病虫害预警防治系统、生态水循环系统、农产品溯源系统，7个展区充分展现了现代农业的特色和发展方向。
                  二号棚：蔬菜花卉育苗与生产研学相结合。该温室设计主要以育苗以及生产研学结合进行规划，育苗板块以蔬菜、花卉栽培进行建设。合理利用空间，将生产区作物与育苗区作物相结合，统一环境因素，提供生产、育苗、新品种研学试验的基地。
                  三号棚：统一管理，规模化生产区。该温室设计主要以生产型进行规划，板块以荷兰几字型落地栽培进行建设。自动化控制与水肥一体化相结合，极大的优化生产流程，降低人员成本，充分适应市场与消费者需求。
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="zpmsdialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_zaip" style="height: 45vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">栽培模式</span>
          </div>
          <el-row class="tz_cov_top" style="height:40vw;"
            v-loading="loading"
            element-loading-spinner="el-loading-spinner"
            element-loading-text=""
            element-loading-background="rgba(0, 0, 0, 0.6)"
          >
            <el-col
              :span="8"
              v-for="(item, index) in leftPlantApiList"
              :key="index"
            >
              <div class="tczpms_list">
                <div class="tczpms_list_top">
                  <span
                    class="tczpms_list_top_lf"
                    style="text-align: center; display: block; float: none"
                    >{{ item.landName }}</span
                  >
                  <!-- <span class="tczpms_list_top_rt">详情>></span> -->
                </div>
                <viewer :images="leftPlantApiList">
                  <img :src="urlimg + item.sceneImg" v-if="item.sceneImg" alt="" class="zpmsbanner"/>
                  <img :src="urlimg + item.landImg" v-else alt="" class="zpmsbanner"/>
                </viewer>
                <el-carousel
                  height="180px"
                  :interval="5000"
                  direction="vertical"
                  :autoplay="true"
                >
                  <el-carousel-item
                    v-for="val in item.plants"
                    :key="val.plantId"
                  >
                    <div class="zpmstime">
                      <span class="zpmstime_left">预计下一批成熟期</span>
                      <img
                        src="@/assets/images/zpmsjt.png"
                        alt=""
                        class="zpmsjt"
                      />
                      <span class="zpmstime_right">{{ val.pickMonthStr }}</span>
                    </div>
                    <el-row>
                      <el-col :span="12">
                        <div class="zpmslist">
                          <img
                            src="@/assets/images/zpmsicon1.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >作物：{{ val.cropName }}</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div
                          class="zpmslist"
                          style="float: right; margin-right: 6%"
                        >
                          <img
                            src="@/assets/images/zpmsicon2.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >面积：{{ val.plantAcre
                            }}{{ item.landAcreUnit_dictText }}</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="zpmslist">
                          <img
                            src="@/assets/images/zpmsicon3.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >周期：约{{ val.growthCycle }}天</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div
                          class="zpmslist"
                          style="float: right; margin-right: 6%"
                        >
                          <img
                            src="@/assets/images/zpmsicon4.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >成熟度：{{ val.growthPercent }}%</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="zpmslist">
                          <img
                            src="@/assets/images/zpmsicon5.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >株数：{{ val.plantNum }}株</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div
                          class="zpmslist"
                          style="float: right; margin-right: 6%"
                        >
                          <img
                            src="@/assets/images/zpmsicon6.png"
                            alt=""
                            class="zpmsicon1"
                          />
                          <span class="zpmslist_name"
                            >产量预估：{{ val.forecastTotalOutput }}公斤</span
                          >
                        </div>
                      </el-col>
                    </el-row>
                  </el-carousel-item>
                </el-carousel>
                <!-- <p class="tczpms_list_text">
                        模式介绍：采用滚筒式种植模式，内部使用营养液，滚 筒可转动，使作物可以有充足光照，配合定制杯和海绵 进行种植。
                      </p> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-dialog
        width="96%"
        top="5vh"
        v-if="sxtdialogTableVisible"
        :visible.sync="sxtdialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_zaip" style="height: 55vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">摄像头</span>
          </div>
          <el-row class="tz_cov_topjk">
            <el-col
              :span="6"
              v-for="(item, index) in homeVideoList"
              :key="index"
            >
              <div class="tczpms_list">
                <div class="tczpms_list_top">
                  <span class="tczpms_list_top_lf">{{ item.deployDes }}</span>
                </div>
                <img
                    src="@/assets/images/zdjkimg.png"
                    class="video-js vjs-default-skin vjs-big-play-centered jczimg"
                    @click="sxtshow(item)"
                    alt=""
                  />
              </div>
            </el-col>
          </el-row>
          <div class="fenye">
            <el-pagination
              @current-change="handleCurrentfxChange"
              :background="true"
              layout="prev, pager, next"
              style="margin-bottom:20px;"
              :page-size="12"
               v-if="sxtdialogTableVisible"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <!-- 监测数据方案 -->
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="jcsjdialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_zaip" style="height: 45vw">
          <img src="@/assets/images/guanbi.png" class="guanbiann" @click="qcguanbi" alt="" />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">监测数据方案</span>
          </div>
          <el-tabs type="border-card">
              <el-tab-pane v-for="(item, index) in deviceSurvDataList" :key="index" >
                <span slot="label">
                  <span class="lvdian4" v-if="item.isOnline==1"></span>
                  <span class="huidian4" v-else></span>
                  <span style="margin-left: 10px;">{{ item.commonName }}</span>
                </span>
                <!-- <span slot="label" ><i class="el-icon-s-order"></i> {{ item.commonName }}（）<span class="lvdian4" v-if="item.isOnline==1"></span>  <span class="huidian4" v-else></span> </span> -->
                <div class="tz_cov_top" style="height:40vw;" >
                    <template v-if="item.soilData" >
                      <img src="@/assets/images/jctrsjbt.png" class="jctrsjbt" alt="" />
                      <el-row>
                        <el-col :span="6" v-for="(val, index) in item.soilData" :key="index">
                          <div :class="val.readStatus=='normal'?'jcsjlistsmrtr':(val.readStatus=='high'?'jcsjlistsmrqxHeight':'jcsjlistsmrqxLow')" @click="jcsjwdtc(val)">
                            <el-row :gutter="20">
                              <el-col :span="10">
                                <img :src="urlimg + val.bsIcon" alt="" class="jcsjicon1" />
                              </el-col>
                              <el-col :span="14">
                                <span class="jcsjicon1_tit">{{ val.name }}</span>
                                <span
                                  class="jcsjicon1_num"
                                  v-if="val.value == '0' || val.value == '0.0'"
                                  ><span>0</span
                                  ><span class="jcsjicon1_dan">{{ val.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="val.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="val.readStatus=='low'" class="updownimg" />
                                </span>
                                <span class="jcsjicon1_num" v-else
                                  ><animate-number
                                    ref="myNumhj1"
                                    from="0"
                                    :to="Number(val.value)"
                                    :key="val.value"
                                    duration="3000"
                                    :formatter="formatter"
                                  ></animate-number
                                  ><span class="jcsjicon1_dan">{{ val.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="val.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="val.readStatus=='low'" class="updownimg" />
                                </span>
                                <!-- <span class="jcsjicon1_numtr">{{ val.value }}
                                  <span class="jcsjicon1_dan">{{ val.unit }}</span>
                                </span> -->
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                      </el-row>
                    </template>
                    <template v-if="item.waterData">
                      <img src="@/assets/images/jcszsjbt.png" class="jctrsjbt" alt="" />
                      <el-row>
                        <el-col :span="6" v-for="(lit, index) in item.waterData" :key="index">
                          <div :class="lit.readStatus=='normal'?'jcsjlistsmrsz':(lit.readStatus=='high'?'jcsjlistsmrqxHeight':'jcsjlistsmrqxLow')" @click="jcsjwdtc(lit)">
                            <el-row :gutter="20">
                              <el-col :span="10">
                                <img :src="urlimg + lit.bsIcon" alt="" class="jcsjicon1"/>
                              </el-col>
                              <el-col :span="14">
                                <span class="jcsjicon1_tit">{{ lit.name }}</span>
                                <span
                                  class="jcsjicon1_num"
                                  v-if="lit.value == '0' || lit.value == '0.0'"
                                  ><span>0</span
                                  ><span class="jcsjicon1_dan">{{ lit.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="lit.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="lit.readStatus=='low'" class="updownimg" />
                                </span>
                                <span class="jcsjicon1_num" v-else
                                  ><animate-number
                                    ref="myNumhj1"
                                    from="0"
                                    :to="Number(lit.value)"
                                    :key="lit.value"
                                    duration="3000"
                                    :formatter="formatter"
                                  ></animate-number
                                  ><span class="jcsjicon1_dan">{{ lit.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="lit.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="lit.readStatus=='low'" class="updownimg" />
                                </span>
                                <!-- <span class="jcsjicon1_numdq" >{{ lit.value }}<span class="jcsjicon1_dan">{{lit.unit }}</span> </span> -->
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                      </el-row>
                    </template>
                    <template v-if="item.airData">
                      <img src="@/assets/images/jcqxsjbt.png" class="jctrsjbt" alt=""/>
                      <el-row>
                        <el-col :span="6" v-for="(res, index) in item.airData" :key="index" >
                          <div  :class="res.readStatus=='normal'?'jcsjlistsmrqx':(res.readStatus=='high'?'jcsjlistsmrqxHeight':'jcsjlistsmrqxLow')" @click="jcsjwdtc(res)">
                            <el-row :gutter="20">
                              <el-col :span="10">
                                <img :src="urlimg + res.bsIcon" alt="" class="jcsjicon1" />
                              </el-col>
                              <el-col :span="14">
                                <span class="jcsjicon1_tit">{{ res.name }}</span>
                                <span
                                  class="jcsjicon1_num"
                                  v-if="res.value == '0' || res.value == '0.0'"
                                  ><span>0</span
                                  ><span class="jcsjicon1_dan">{{ res.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="res.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="res.readStatus=='low'" class="updownimg" />
                                </span>
                                <span class="jcsjicon1_num" v-else
                                  ><animate-number
                                    ref="myNumhj1"
                                    from="0"
                                    :to="Number(res.value)"
                                    :key="res.value"
                                    duration="3000"
                                    :formatter="formatter"
                                  ></animate-number
                                  ><span class="jcsjicon1_dan">{{ res.unit }}</span>
                                  <img src="@/assets/images/up.png" alt="" v-if="res.readStatus=='high'" class="updownimg" />
                                  <img src="@/assets/images/down.png" alt="" v-else-if="res.readStatus=='low'" class="updownimg" />
                                </span>
                                <!-- <span class="jcsjicon1_numdq" >{{ res.value }}
                                  <span class="jcsjicon1_dan">{{ res.unit }}</span>
                                </span> -->
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                      </el-row>
                    </template>
                </div>
              </el-tab-pane>
          </el-tabs>
        </div>
      </el-dialog>
      <el-dialog
        width="45%"
        :visible.sync="zpshowdialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_zaip" style="height: 17vw; margin-left: 20%">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">滚筒栽培模式</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
          </div>
          <el-row class="tz_cov_top">
            <el-col :span="12">
              <img
                src="@/assets/images/zpmsbanner.png"
                alt=""
                class="zpmsbanner"
              />
            </el-col>
            <el-col :span="12">
              <div class="zpmstime">
                <span class="zpmstime_left">预计下一批成熟期</span>
                <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                <span class="zpmstime_right">2023.12.03</span>
              </div>
              <el-row>
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon1.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">作物：甘蓝</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon2.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">面积：192m²</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon3.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">周期：约90天</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon4.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">成熟度：12%</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon5.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">株数：390株</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon6.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">产量预估：12T</span>
                  </div>
                </el-col>
              </el-row>
              <p class="tczpms_list_text">
                模式介绍：采用滚筒式种植模式，内部使用营养液，滚
                筒可转动，使作物可以有充足光照，配合定制杯和海绵 进行种植。
              </p>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-dialog
        width="45%"
        :visible.sync="lxlizpialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_zaip" style="height: 18vw; margin-left: 20%">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="zpmsshou_tc">
            <img src="@/assets/images/zpjianlf.png" class="zpjianlf" alt="" />
            <span class="zpmsshou_tc_tit">螺旋立柱栽培</span>
            <img src="@/assets/images/zpjianrt.png" class="zpjianrt" alt="" />
          </div>
          <el-row class="tz_cov_top">
            <el-col :span="12">
              <img src="@/assets/images/luoxzp.jpg" alt="" class="zpmsbanner" style="height:250px" />
            </el-col>
            <el-col :span="12">
              <div class="zpmstime">
                <span class="zpmstime_left">预计下一批成熟期</span>
                <img src="@/assets/images/zpmsjt.png" alt="" class="zpmsjt" />
                <span class="zpmstime_right">2023.12.03</span>
              </div>
              <el-row style="margin-bottom: 0px">
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon1.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">作物：花红圆叶苋菜</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon2.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">面积：192m²</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon3.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">周期：约90天</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon4.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">成熟度：12%</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist">
                    <img
                      src="@/assets/images/zpmsicon5.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">株数：390株</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="zpmslist" style="float: right; margin-right: 6%">
                    <img
                      src="@/assets/images/zpmsicon6.png"
                      alt=""
                      class="zpmsicon1"
                    />
                    <span class="zpmslist_name">产量预估：12T</span>
                  </div>
                </el-col>
              </el-row>
              <p class="tczpms_list_text">
                模式介绍：它模仿蜗牛的螺旋壳形状设计，以直立柱为主干，以螺旋曲线为底部，通过特殊的栽培手法和剪枝技术，将植物栽种于立柱上，形成仿生螺旋立柱状的艺术植栽。这种栽培模式具有美观、节约空间、方便管理等优点，受到越来越多花卉爱好者的追捧。
              </p>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-dialog
        width="96%"
        top="5vh"
        :visible.sync="sxtshowdialogTableVisible"
        :modal-append-to-body="false"
        v-if="sxtshowdialogTableVisible"
      >
        <div 
          class="tz_cov_zaip" style="height: 55vw;"  v-if="itemdeployCode"
          v-loading="loading"
          element-loading-spinner="el-loading-spinner"
          element-loading-text=""
          element-loading-background="rgba(0, 0, 0, 0.6)"
        >
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi1"
            alt=""
          />
          <div class="tz_cov_topchanye" style="margin-bottom:0px;">
            <span class="tz_cov_top_tit">{{ showSeployDes }}</span>
          </div>
          <div class="sxtshowcov" style="width:100%">
            <!-- <el-popover
            placement="bottom"
            width="160"
            v-model="visible">
            </el-popover> -->
            
            <el-row v-if="sxtdeploySecondaryType=='ball'||sxtdeploySecondaryType=='halfball'">
              <el-col :span="24">
                <div class="video-js vjs-default-skin zdjkimgtc"  style="width:100%;" :id="itemdeployCode"></div>
              </el-col>
              <div style="
                    position: absolute;
                    width: 10%;
                    right: 10px;
                    top: 10px;
                    z-index: 333;
                  "
                >
                <div>
                  <img
                    src="@/assets/images/main_control_bg.png"
                    class="jd_jkssimg1"
                    alt=""
                  />
                  <i
                    class="el-icon-arrow-left sxtczlf1"
                    @click="sxtcz(itemdeployCode, 2)"
                  ></i>
                  <i
                    class="el-icon-arrow-right sxtczrt1"
                    @click="sxtcz(itemdeployCode, 3)"
                  ></i>
                  <i
                    class="el-icon-arrow-down sxtczdw1"
                    @click="sxtcz(itemdeployCode, 1)"
                  ></i>
                  <i
                    class="el-icon-arrow-up sxtczup1"
                    @click="sxtcz(itemdeployCode, 0)"
                  ></i>
                </div>

                <div>
                  <img
                    src="@/assets/images/video_ctrl1.png"
                    class="video_ctrlqp1"
                    alt=""
                    @click="sxtcz(itemdeployCode, 8)"
                  />
                  <img
                    src="@/assets/images/video_ctrl2.png"
                    class="video_ctrlqp2"
                    alt=""
                    @click="sxtcz(itemdeployCode, 9)"
                  />
                </div>
                <!-- <i class="el-icon-video-camera sxtcz" slot="reference"></i> -->
              </div>
            </el-row>
            <el-row v-else>
              <el-col :span="24">
                <div class="video-js vjs-default-skin zdjkimgtc" :id="itemdeployCode"></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-dialog>
      <!-- 监测数据弹窗 -->
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="trwddialogTableVisible"
        v-if="trwddialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_chanye" style="height: 45vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye ">
            <span class="tz_cov_top_tit">{{ trwdNmae }}</span>
          </div>
          <div class="trwdcov jgdhei">
            <div class="trwdcov_top">
              <el-tabs v-model="activeTime" @tab-click="timetcxuan" class="timeTab"> 
                <el-tab-pane label="小时" name="first" ></el-tab-pane>
                <el-tab-pane label="日均" name="second" ></el-tab-pane>
                <el-tab-pane label="月均" name="third" ></el-tab-pane>
              </el-tabs>
              <el-date-picker
              class="trwdcov_top_time"
                v-model="timetr"
                type="date"
                @blur="timetrpicker"
                placeholder="选择日期"
                v-if="summrayMode=='dayhours'"
              >
              </el-date-picker>
              <el-date-picker
                v-model="xayvalue"
                type="month"
                class="trwdcov_top_time"
                placeholder="选择月"
                @blur="timetrpicker"
                v-else-if="summrayMode=='monthDays'"
              >
              </el-date-picker>
              <el-date-picker
                v-model="xanvalue"
                type="year"
                class="trwdcov_top_time"
                placeholder="选择年"
                @blur="timetrpicker"
                v-else
              >
              </el-date-picker>
              <img src="@/assets/images/chaxunbg.png" alt="" style="float:left;width:74px;height:48px;cursor:pointer;margin-right:3%;" @click="tanchuangshuj" > 
              <span class="trwdcov_top_tit">展示方式</span>
              <el-radio-group v-model="radiotr" @change="agreeChange">
                <el-radio label="1">折线</el-radio>
                <el-radio label="2">表格</el-radio>
              </el-radio-group>
              <span class="trwdcov_top_zcz" v-if="limitStrValue=='*~*'"></span>
              <span class="trwdcov_top_zcz" v-else>
                正常值：{{limitStrValue}}{{unitValue}}
              </span>
            </div>
            <img src="@/assets/images/vloading.gif" alt="" style="width:60px;height:60px;margin:0 auto;margin-top:300px;" v-if="trbgzhuanq"> 
            <template v-else-if="trbg">
              <div id="main" style="width: 100%; height: 650px" ></div>
              <el-popover
                placement="top"
                width="360"
                trigger="hover"
                v-model="visibleShujufenx">
                <p v-if="envAdviceVlaue">
                  当前种植：
                  <span style="display:block;" v-html="shujufenxi(envAdviceVlaue)"></span>
                </p>
                <p v-if="plantAdviceVlaue">其他推荐：
                  <span style="display: block;text-indent: 2em;">{{plantAdviceVlaue}}</span>
                </p>
                <span slot="reference" class="shujufx">数据分析</span>
              </el-popover>
            </template>
            <template v-else>
              <el-table
                :data="formListVlaue"
                v-if="trzx"
                height="680"
                border
                style="margin-top: 1vw; background: none"
              >
                <el-table-column prop="date" label="时间"> </el-table-column>
                <el-table-column prop="name" :label="'数值单位：' + unitValue">
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </el-dialog>
      <!-- 监测数据更多弹窗 -->
      <!-- 积温积雨弹窗 -->
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="jwjydialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_chanye" style="height: 45vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye ">
            <span class="tz_cov_top_tit">2015-2024年月均积温积雨统计</span>
          </div>
          <div class="trwdcov jgdhei">
            <div class="trwdcov_top">
              <span class="trwdcov_top_tit">统计切换</span>
              <el-radio-group v-model="radiotr" @change="jwjyChange">
                <el-radio label="1">积温</el-radio>
                <el-radio label="2">积雨</el-radio>
              </el-radio-group>
              <span class="trwdcov_top_zcz" v-if="jwtjNum=='1'">积温统计：2922℃</span>
              <span class="trwdcov_top_zcz" v-else>积雨统计：320mm</span>
            </div>
            <template v-if="jwtjNum=='1'">
              <div id="jiwennum" style="width: 100%; height: 650px" ></div>
            </template>
            <template v-else>
              <div id="jiyunum" style="width: 100%; height: 650px" ></div>
            </template>
            
          </div>
        </div>
      </el-dialog>
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="jcsjwddialogTableVisible"
        v-if="jcsjwddialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_chanye" style="height: 45vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="jcsjguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">{{ trwdNmae }}</span>
          </div>
          <div class="trwdcov jgdhei">
            <div class="trwdcov_top">
              <el-tabs v-model="activeTime" @tab-click="timetcxuan" class="timeTab"> 
                <el-tab-pane label="小时" name="first" ></el-tab-pane>
                <el-tab-pane label="日均" name="second" ></el-tab-pane>
                <el-tab-pane label="月均" name="third" ></el-tab-pane>
              </el-tabs>
              <el-date-picker
                class="trwdcov_top_time"
                v-model="timetr"
                type="date"
                 @blur="timetrpicker"
                 placeholder="选择日期"
                 v-if="summrayMode=='dayhours'"
              >
              </el-date-picker>
              <el-date-picker
                v-model="xayvalue"
                type="month"
                class="trwdcov_top_time"
                placeholder="选择月"
                @blur="timetrpicker"
                v-else-if="summrayMode=='monthDays'"
              >
              </el-date-picker>
              <el-date-picker
                v-model="xanvalue"
                type="year"
                class="trwdcov_top_time"
                placeholder="选择年"
                @blur="timetrpicker"
                v-else
              >
              </el-date-picker>
              <img src="@/assets/images/chaxunbg.png" alt="" style="float:left;width:74px;height:48px;cursor:pointer;margin-right:3%;" @click="tanchuangshuj" > 
              <span class="trwdcov_top_tit">展示方式</span>
              <el-radio-group v-model="radiotr" @change="agreeChange">
                <el-radio label="1">折线</el-radio>
                <el-radio label="2">表格</el-radio>
              </el-radio-group>
              <span class="trwdcov_top_zcz" v-if="limitStrValue=='*~*'"></span>
              <span class="trwdcov_top_zcz" v-else>
                正常值：{{limitStrValue}}{{unitValue}}
              </span>
            </div>
            <img src="@/assets/images/vloading.gif" alt="" style="width:60px;height:60px;margin:0 auto;margin-top:300px;" v-if="trbgzhuanq"> 
            <template v-else-if="trbg">
              <div id="main" style="width: 100%; height: 650px" ></div>
              <el-popover
                placement="top"
                width="360"
                trigger="hover"
                v-model="visibleShujufenx">
                <p v-if="envAdviceVlaue">
                  当前种植：
                  <span style="display:block;" v-html="shujufenxi(envAdviceVlaue)"></span>
                </p>
                <p v-if="plantAdviceVlaue">其他推荐：
                  <span style="display: block;text-indent: 2em;">{{plantAdviceVlaue}}</span>
                </p>
                <span slot="reference" class="shujufx">数据分析</span>
              </el-popover>
            </template>
            <template v-else>
              <el-table
                :data="formListVlaue"
                v-if="trzx"
                height="680"
                border
                style="margin-top: 1vw; background: none"
              >
                <el-table-column prop="date" label="时间"> </el-table-column>
                <el-table-column prop="name" :label="'数值单位：' + unitValue">
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="wskzdialogTableVisible"
        :modal-append-to-body="false"
      >
        <div class="tz_cov_chanye" style="height: 45vw">
          <img
            src="@/assets/images/guanbi.png"
            class="guanbiann"
            @click="qcguanbi"
            alt=""
          />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">操作记录</span>
          </div>
          <div class="trwdcov">
            <!-- <div class="trwdcov_top">
              <span class="trwdcov_top_tit">时间范围</span>
              <el-date-picker
                class="trwdcov_top_time"
                v-model="timetr"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div> -->
            <el-table
              :data="cztableData"
              border
              class="tctablist"
              style="width: 100%; margin-top: 1vw; background: none"
            >
              <el-table-column prop="oprationName" label="操作状态" width="33%">
                <template slot-scope="scope">
                  <div :style="{ color: scope.row.oprationName =='开' ? '#21D84f' : scope.row.oprationName =='关' ? '#fd4f4f' : '#e37405' }">
                    {{ scope.row.oprationName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="operatorUserName" label="操作人" width="33%">
              </el-table-column>
              <el-table-column prop="updateTime" label="操作时间" width="33%">
              </el-table-column>
            </el-table>
            <el-pagination layout="prev, pager, next" :total="totalczjl" style="margin-top:20px;"  @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        width="70%"
        top="9vh"
        v-if="dialogVisiblejiazaiz"
        :visible.sync="dialogVisiblejiazaiz"
        :modal-append-to-body="false"
        >
        <img src="@/assets/images/jiazaiz.gif" alt="" style="width:640px;height:360px;margin-top:200px;">
      </el-dialog>
      <el-dialog
        width="70%"
        top="9vh"
        :visible.sync="sfythdialogTableVisible"
        v-if="sfythdialogTableVisible"
        :modal-append-to-body="false"
        v-loading="loading"
            element-loading-spinner="el-loading-spinner"
            element-loading-text=""
            element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <div class="tz_cov_zaip" style="height: 45vw">
          <img src="@/assets/images/guanbi.png" class="guanbiann" @click="qcguanbi" alt="" />
          <div class="tz_cov_topchanye">
            <span class="tz_cov_top_tit">水肥一体化</span>
          </div>
           <!-- v-if="level1List" -->
          <div class="sfythCovTc"
            
          >
            <img src="@/assets/images/waterfeiji.png" alt="" class="waterfeijiTc"/>
            <div class="sfythCov_lft">
                <span class="sfythCov_lft_tit" v-for="run in runStatusOverList" :key="run.id">{{run.prop_name}} <span class="sfythCov_lft_num">{{run.pval}} <span style="font-size:0.8vw;" v-show="run.prop_name=='灌溉计时'">s/0分</span> </span> </span>
            </div>
            <div class="sfythCovTc_taizh">
                <span class="sfythCovTc_taizh_tit">运行状态指示</span>
                <div class="sfythCov_opendow">
                  <span class="sfythCov_lft_opdwtit"><span class="sfythCov_yuand"></span> 使用中 </span>
                  <span class="sfythCov_lft_opdwtit"><span class="sfythCov_yuandwat"></span> 已关闭 </span>
              </div>
            </div>
            <div class="sfythCov_sfei">
                <div class="sfythCov_sfei_feilit" v-for="item in level2List" :key="item.id">
                    <span class="sfythCov_sfei_feilit_tit">{{item.prop_name}}</span>
                    <img src="@/assets/images/open01.png" v-if="item.pval=='1'" alt="" class="sfythCov_sfei_feilit_ioen"  />
                    <img src="@/assets/images/close01.png" v-else alt="" class="sfythCov_sfei_feilit_ioen"  />
                </div>
            </div>
            <div class="sfythCov_fa">
                <div class="sfythCov_sfei_falit" v-for="level3 in level3List" :key="level3.id">
                    <span class="sfythCov_sfei_feilit_tit">{{level3.prop_name}}</span>
                    <img src="@/assets/images/close04.png" v-if="level3.pval=='0'" alt="" class="sfythCov_sfei_falit_ioen"  />
                    <img src="@/assets/images/open04.png" v-else alt="" class="sfythCov_sfei_falit_ioen"  />
                </div>
            </div>
            <div class="sfythCov_fa2">
                <div class="sfythCov_sfei_falit" v-for="level4 in level4List" :key="level4.id">
                    <img src="@/assets/images/close04.png" v-if="level4.pval=='0'" alt="" class="sfythCov_sfei_falit_ioen"  />
                    <img src="@/assets/images/open04.png" v-else alt="" class="sfythCov_sfei_falit_ioen"  />
                    <span class="sfythCov_sfei_feilit_tit">{{level4.prop_name}}</span>
                </div>
            </div>
            <div class="sfythCov_sfb">
              <span class="sfythCov_sfei_feilit_tit">{{sfbList.prop_name}}:<span v-if="sfbList.pval=='0'">关</span><span v-else>开</span></span>
                <img src="@/assets/images/close02.png" alt="" v-if="sfbList.pval=='0'" class="sfythCov_sfei_sfb_ioen"  />
                <img src="@/assets/images/close02.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
            </div>
            <div class="sfythCov_bsb">
              <span class="sfythCov_sfei_feilit_tit">{{bsbList.prop_name}}:<span v-if="bsbList.pval=='0'">关</span><span v-else>开</span></span>
              <img src="@/assets/images/close02.png" v-if="bsbList.pval=='0'" alt="" class="sfythCov_sfei_sfb_ioen"  />
                <img src="@/assets/images/open02.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
            </div>
            <div class="sfythCov_bsfsm">
                <span class="sfythCov_sfei_feilit_tit">{{bsfList.prop_name}}:  <span v-if="bsfList.pval=='0'">关</span><span v-else>开</span></span>
                <img src="@/assets/images/close03.png" alt="" v-if="bsfList.pval=='0'" class="sfythCov_sfei_sfb_ioen"  />
                <img src="@/assets/images/open03.png" alt="" v-else class="sfythCov_sfei_sfb_ioen"  />
            </div>
            <div class="sfythCov_ggfsm">
                <span class="sfythCov_sfei_feilit_tit">{{ggfList.prop_name}}:<span v-if="ggfList.pval=='0'">关</span><span v-else>开</span></span>
                <img src="@/assets/images/close03.png" v-if="ggfList.pval=='0'" alt="" class="sfythCov_sfei_sfb_ioen"  />
                <img src="@/assets/images/open03.png" v-else alt="" class="sfythCov_sfei_sfb_ioen"  />
            </div>
            <span class="sfythCovTc_sdfm">{{sdfList.prop_name}}: <span style="color:#64FDA9;">{{sdfList.pval}}%</span> </span>
            <div class="sfytEchTwo" style="width:87px;height:482px;" id='chartsfythTwo'></div>
          </div>
          <!-- <img src="@/assets/images/vloading.gif" alt="" style="width:60px;height:60px;margin:0 auto;margin-top:300px" v-else>  -->
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { gardenLeft, greenHouse, gardenBelow, camSurvPage, deviceSummary, cameraStart, cameraStop, controlItemList, envSurvData, camSurvList, deviceSurvData,leftPlant,alertList,getAllDeviceVariable,cmdDevice,deviceOprateLog,IrrigateFastStatus} from '@/api';
import Spin from '@/components/Spin';
import Videojs from "video.js"; // 引入Videojs
import "video.js/dist/video-js.css";
import "echarts-liquidfill";
import mqtt from 'mqtt/dist/mqtt'
var alias = Math.random().toString(16).substr(2, 8)
  export default {
    name: 'Home',
    components:{
        Spin
    },
    data() {
      return {
        getTimes:'', //刷新dome
        urlimg:'https://screen.zgzhny.com/zh-api/',
        // urlimg:'https://zy.sxzooh.com/zh-api/',
        yqjsbanlist:[
          { prc: require("@/assets/images/qyjsbanner1.jpg" )},
          { prc: require("@/assets/images/qyjsbanner2.jpg" ) },
          { prc: require("@/assets/images/qyjsbanner3.jpg" ) },
          { prc: require("@/assets/images/qyjsbanner4.jpg" ) },
          { prc: require("@/assets/images/qyjsbanner5.jpg" ) },
          { prc: require("@/assets/images/qyjsbanner6.jpg" ) },
          { prc: require("@/assets/images/qyjsbanner7.jpg" ) },
        ],
        activeName: 'first',//导航上面
        activeTime:'first',//弹窗
        tabPosition: 'right',//导航侧面
        nowTime: "",
        night: false,
        qczddialogTableVisible: false,
        zpmsdialogTableVisible:false,
        sxtdialogTableVisible:false,
        sxtshowdialogTableVisible:false,
        jcsjdialogTableVisible:false,
        sfythdialogTableVisible:false,
        zpshowdialogTableVisible:false,
        lxlizpialogTableVisible:false,
        trwddialogTableVisible:false,
        jwjydialogTableVisible:false,
        jcsjwddialogTableVisible:false,
        wskzdialogTableVisible:false,
        dialogVisiblejiazaiz:false,
        dialogVisible: false,
        visible:'',
        visibleShujufenx: false,
        timetr:new Date(),
        xanvalue:new Date(),
        xayvalue:new Date(),
        radiotr: '1',
        cztableData: [],
        warningList: [],
        zpmslist: [],
        trbg:true,
        trzx:false,
        homelist: 'yqgl',
        wsimg:'yhws',
        optionsVideo: {
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: "auto", // 预加载
          controls: true, // 显示播放的控件
          hls: true,
          loop: true, // 导致视频一结束就重新开始。
          aspectRatio: '16:9',
        },
        listData:[],
        swtvalue: false,
        radio3:'1',
        tabListShow:true,
        // 接口数据
        farminfo:'',
        farmAcreUnit_dictText:'',
        plantInfos:[],
        greenHouseList:[],
        greenHouseId:'',
        deviceCount:'0',
        airdeviceCount:'0',
        soildeviceCount:'0',
        sfytdeviceCount:'0',
        soilDataList:[],
        airDataListHome:[],
        airDataListHome2:[
          {
            bsIcon: "sys/bs/icon/zhandian_icon_turangwendu.png",
            color: "#C71585",
            icon: "sys/icon/zhandian_icon_turangwendu.png",
            item: "dataSoilTemp",
            lastUpdate: "48秒",
            limitStr: "11.0~12.0",
            mobileIcon: "sys/mobile/icon/zhandian_icon_turangwendu.png",
            name: "土壤温度",
            readStatus: "high",
            unit: "℃",
            value: "13.2",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_turangshidu.png",
            color: "#6495ED",
            icon: "sys/icon/zhandian_icon_turangshidu.png",
            item: "dataSoilWet",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_turangshidu.png",
            name: "土壤湿度",
            readStatus: "normal",
            unit: "%",
            value: "23.4",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_ec.png",
            color: "#92D0D0",
            icon: "sys/icon/zhandian_icon_ec.png",
            item: "dataSoilDdl",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_ec.png",
            name: "电导率",
            readStatus: "normal",
            unit: "uS/cm",
            value: "92",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_ph.png",
            color: "#92D0D0",
            icon: "sys/icon/zhandian_icon_ph.png",
            item: "dataSoilPh",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_ph.png",
            name: "土壤PH",
            readStatus: "normal",
            unit: "",
            value: "7.12",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_nion.png",
            color: "#92D0F3",
            icon: "sys/icon/zhandian_icon_nion.png",
            item: "dataSoilNion",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_nion.png",
            name: "氮离子",
            readStatus: "normal",
            unit: "mg/KG",
            value: "15",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_pion.png",
            color: "#92D0F5",
            icon: "sys/icon/zhandian_icon_pion.png",
            item: "dataSoilPion",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_pion.png",
            name: "磷离子",
            readStatus: "normal",
            unit: "mg/KG",
            value: "14",
          },
          {
            bsIcon: "sys/bs/icon/shebei_icon_k-.png",
            color: "#92D0F7",
            icon: "sys/icon/shebei_icon_k-.png",
            item: "dataSoilKion",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/shebei_icon_k-.png",
            name: "钾离子",
            readStatus: "normal",
            unit: "mg/KG",
            value: "37",
          },
          {
            bsIcon: "sys/bs/icon/shebei_icon_nh4-.png",
            color: "#92D0F9",
            icon: "sys/icon/shebei_icon_nh4-.png",
            item: "dataSoilKion",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/shebei_icon_nh4-.png",
            name: "土壤铵离子",
            readStatus: "normal",
            unit: "mg-L",
            value: "12.6",
          },
          {
            bsIcon: "sys/bs/icon/shebei_icon_no3-.png",
            color: "#92D0FE",
            icon: "sys/icon/shebei_icon_no3-.png",
            item: "dataSoilKion",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/shebei_icon_no3-.png",
            name: "土壤硝酸根离子",
            readStatus: "normal",
            unit: "ppm",
            value: "17",
          },
          {
            bsIcon: "sys/bs/icon/shebei_icon_qixiang.png",
            color: "#92D0FE",
            icon: "sys/icon/shebei_icon_qixiang.png",
            item: "dataAirTemp",
            lastUpdate: "48秒",
            limitStr: "20.0~25.0",
            mobileIcon: "sys/mobile/icon/shebei_icon_qixiang.png",
            name: "大气温度",
            readStatus: "low",
            unit: "℃",
            value: "15.9",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_kongqishidu.png",
            color: "#EE701C",
            icon: "sys/icon/zhandian_icon_kongqishidu.png",
            item: "dataAirWet",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_kongqishidu.png",
            name: "大气湿度",
            readStatus: "low",
            unit: "%RH",
            value: "44.7",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_sunrate.png",
            color: "#EE701C",
            icon: "sys/icon/zhandian_icon_sunrate.png",
            item: "dataAirWet",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_sunrate.png",
            name: "照度",
            readStatus: "normal",
            unit: "Lux",
            value: "7080",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_cod2.png",
            color: "#EE701C",
            icon: "sys/icon/zhandian_icon_cod2.png",
            item: "dataAirCod",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_cod2.png",
            name: "二氧化碳",
            readStatus: "normal",
            unit: "ppm",
            value: "424",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_leaftemp.png",
            color: "#EE701C",
            icon: "sys/icon/zhandian_icon_leaftemp.png",
            item: "dataLeafTemp",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_leaftemp.png",
            name: "叶面温度",
            readStatus: "normal",
            unit: "℃",
            value: "13.7",
          },
          {
            bsIcon: "sys/bs/icon/zhandian_icon_leafwet.png",
            color: "#EE701C",
            icon: "sys/icon/zhandian_icon_leafwet.png",
            item: "dataLeafWet",
            lastUpdate: "48秒",
            limitStr: "~",
            mobileIcon: "sys/mobile/icon/zhandian_icon_leafwet.png",
            name: "叶面湿度",
            readStatus: "normal",
            unit: "%",
            value: "0.0",
          },
        ],
        deployCodeId:'',
        predictProfit:'',
        predictOutput:'',
        farmAcre:'',
        homeVideoList:[],
        deviceUrl:'',
        showDeviceUrl:'',
        showDeployCode:'',
        showSeployDes:'',
        zmvariableList:[],
        controlItemListNmuber:[],
        bwvariableList:[],
        tfvariableList:[],
        nwzyvariableList:[],
        fjzyvariableList:[],
        slzyvariableList:[],
        airDataList:[],
        hjsoilDataList:[],
        inoutCamList:[],
        landCamList:[],
        formListVlaue:[],
        trwdNmae:'',
        deviceSurvDataList:[],
        unitValue:'',
        descriptionValue:'',
        farmId:'',
        auths:'',
        loading: true,
        loadingHome:true,
        pageNo:1,
        pageSize:12,
        leftPlantApiList:[],
        tip: '加载中...',
        spinning: true,
        opdeListOne:[],
        opdeListTwo:[],
        opdeListOverOne:'',
        opdeListOverTwo:'',
        kaigtlist:[],
        listDatac:[], //一号
        totalczjl:null,
        total:0,
        jkpagesize:1,
        jkcurrentPage:13,
        cameraCount:'',
        waterCount:'',
        limitStrValue:'',
        // 鼠标是否操作开始
        lastTime: null, // 鼠标最后一次点击时间
        currentTime: null, // 当前时间
        sys_timeout: 100 * 60 * 1000, // 系统安全时间
        check_time: 30 * 1000, //探测间隔时间
        timer: null,  // 定时器
        // 鼠标是否操作结束
        level2List:[],
        level3List:[],
        level4List:[],
        level1List:'',
        runStatusOverList:[],
        bsfList:'',
        bsbList:'',
        sdfList:'',
        sfbList:'',
        ggfList:'',
        deploySecondaryType:'',
        itemdeployCode:'',
        sxtdeploySecondaryType:'',
        sxtshowDeployCode:'',
        reloadInterval: null,
        client: {},
        clientSf:null,
        messageSf:'',
        message:'',
        gropList:{},
        playerRight:[],
        playerleft:[],
        // mqtt开始
        options: {
            clean: true,	// 保留会话
            connectTimeout: 10000,	// 超时时间
            reconnectPeriod: 7000,	// 重连时间间隔
            username: 'mqtt', //注册mqtt 用户名
            password: 'mqtt', //注册mqtt 密码
            // protocolVersion: 5,
            // protocolId: 'MQTT',
            clientId: alias, // 要保证全局唯一
        },
        // mqtt结束
        summrayMode:'dayhours',
        jwtjNum:'1',//积温积雨
        plantAdviceVlaue:'',
        envAdviceVlaue:''
      }
    },
    created() {
      console.log("option跳转",this.$route.query.farmId)
       setInterval(this.startAnimate,10000);
       setTimeout(() => {
          this.spinning = false
          this.loadingHome = false
        }, 2000);
      //  window.sessionStorage.setItem("token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDA5MjUwMDksInVzZXJuYW1lIjoibGdsIn0.SqNSjw8AyrOYuq-oYmQy2Z6cB0vUBNKFis8VrvtgeWM");
       this.farmId = '1713823667042779138'
       this.auths = 'zhkj.20231124_jdx'
      //  window.sessionStorage.setItem("token", option.token);  //发布正式版
      //  this.auths = this.$route.query.auths //发布正式版
      //  this.farmId = this.$route.query.farmId  //发布正式版
      
      this.reloadInterval = setInterval(() => {
        if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          // if (this.homelist == 'hjjc') {
          //   this.IrrigateFastStatusApi(this.auths,this.greenHouseIdnum);
          // }
      }, 1000 * 60) // 设置每分钟更新一次
    },
    computed:{
			optionSetting(){
				return{
					step: 0.3, // 数值越大速度滚动越快
					limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					autoPlay: true, // 是否自动滚动
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				}
			},
		},
    mounted(){
      
      // 实时时间
      this.getNowTime();
      setTimeout(() => {
          this.$nextTick(() => {
              this.greenHouseApi(this.farmId,this.auths)
        })
      }, 200);
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods: {
      lockAlls(){
        window.open('https://www.tianqi.com/yuxian/')
      },
      // 天气弹窗
      handleClose(done) {
        this.dialogVisible = false
      },
      handleScroll(e){
          let ostop = document.getElementsByClassName('tz_cov_chanye')[0].scrollTop
         console.log(ostop)
         if(ostop >= 1115){
          document.getElementsByClassName('tz_cov_chanye')[0].scrollTop = 1115

         }


        console.log(e)
      },
      fixIframeScrollPosition() {
        const iframe112 = this.$refs.iframe112;
        if (iframe112) {
          // 假设你想要滚动到的位置
          const scrollTop = 500;
          iframe112.onload = () => {
            // 对于IE
            iframe112.contentWindow.document.body.scrollTop = scrollTop;
            // 对于其他浏览器
            iframe112.contentWindow.scrollTo(0, scrollTop);
          };
        }
      },
      swtvalueBtn(e,downIotUrl,upIotUrl,variableName){
        console.log("eeeeee",e)
        this.dialogVisiblejiazaiz = true;
        let obj = {
            "f": "s",
            "d": [{
                "pid": variableName,
                "sid": "ModbusTCP",
                "v": e+""
            }]
        }
        this.client[upIotUrl].publish(downIotUrl, JSON.stringify(obj));
        this.getTimes = new Date().getTime()
      },
      swtvalueBtn1(e,downIotUrl,upIotUrl,variableName){
        console.log(".............",e,downIotUrl,upIotUrl,variableName)
        this.dialogVisiblejiazaiz = true;
        let obj = {
            "f": "s",
            "d": [{
                "pid": variableName,
                "sid": "ModbusTCP",
                "v": "1"
            }]
        }
        this.client[upIotUrl].publish(downIotUrl, JSON.stringify(obj));
        this.getTimes = new Date().getTime()
      },
      swtvalueBtn2(e,downIotUrl,upIotUrl,variableName){
        this.dialogVisiblejiazaiz = true;
        let obj = {
            "f": "s",
            "d": [{
                "pid": variableName,
                "sid": "ModbusTCP",
                "v": "1"
            }]
        }
        this.client[upIotUrl].publish(downIotUrl, JSON.stringify(obj));
        this.getTimes = new Date().getTime()
      },
      swtvalueBtn3(e,downIotUrl,upIotUrl,variableName){
        this.dialogVisiblejiazaiz = true;
        let obj = {
            "f": "s",
            "d": [{
                "pid": variableName,
                "sid": "ModbusTCP",
                "v": "1"
            }]
        }
        this.client[upIotUrl].publish(downIotUrl, JSON.stringify(obj));
        this.getTimes = new Date().getTime()
      },
      getAllDeviceVariableApi(auths,deployId){
        getAllDeviceVariable(auths,deployId).then(res=>{
          console.log("000000",res)
        })
      },
      alertListApi(auths){
        this.loading = true
        alertList(auths,this.farmId).then(res=>{
          this.warningList = res.result
          if (res.code==200) {
            this.loading = false
          }
        })
      },
      leftPlantApi(farmId,greenHouseId,auths,pageNo,pageSize){
        this.loading=true
        leftPlant(farmId,greenHouseId,auths,pageNo,pageSize).then(res=>{
            this.leftPlantApiList = res.result.records
            if (res.result.total>0) {
              this.loading = false
            }
        })
      },
      // 视频详情
      camSurvListApi(farmId,greenHouseId,auths){
        this.landCamList = []
        this.inoutCamList = []
        this.$forceUpdate()
        camSurvList(farmId,greenHouseId,auths).then(res=>{
          this.landCamList = res.result.landCamList
          this.inoutCamList = res.result.pestCamList
          if(this.inoutCamList.length == 0){
            this.inoutCamList = res.result.landCamList.slice(5,8)
          }
          this.$nextTick(()=>{
            this.inoutCamList.map((itm,index) => {
              setTimeout(() =>{
                this.playerleft[index] = new EZUIKit.EZUIKitPlayer({
                  id: itm.deployCode , // 视频容器ID+ 'L01'
                  accessToken: itm.ysToken,
                  url: itm.deviceIotUrl,
                  template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                  audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                  autoplay: true,
                  width:480,
                  height:334,
                  handleSuccess: () => {
                    setTimeout(() => {
                      this.player && this.player.closeSound()
                    }, 100)
                    // console.log("播放成功回调函数，此处可执行播放成功后续动作")
                  },
                })
                this.$forceUpdate()
              }, 300)
            })
            this.landCamList.map((val,index)=>{
              setTimeout(() =>{
                  this.playerRight[index] = new EZUIKit.EZUIKitPlayer({
                    id: val.deployCode, // 视频容器ID   + 'L01'
                    accessToken: val.ysToken,
                    url: val.deviceIotUrl,
                    template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    autoplay: true,
                    width:380,
                    height:300,
                    handleSuccess: () => {
                      setTimeout(() => {
                        this.player && this.player.closeSound()
                      }, 100)
                      // console.log("播放成功回调函数，此处可执行播放成功后续动作")
                    },
                  })
                  this.$forceUpdate()
                }, 300)
            })
          })
        })
      },
      envSurvDataApi(farmId,greenHouseId,auths){
         envSurvData(farmId,greenHouseId,auths).then(res=>{
            this.airDataList = res.result.airData
            this.hjsoilDataList = res.result.soilData
            // this.warningList = res.result.warningList
        })
      },
      // mqtt开始
      connectMqtt(upIotUrl) {
            var client = null;
            this.options.clientId = Math.random().toString(16).substr(2, 8);
            client = mqtt.connect('wss://mqtt.byunsz.com/mqtt', this.options);
            client.on('connect', () => {
                client.subscribe(upIotUrl);
            });
            client.on('disconnect', (req) => { })
            client.on('message', (topic, message) => {
                this.message = message.toString();
                this.zmvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableName == items.pid && item.upIotUrl==topic) {
                            item.variableValue = Number(items.v) 
                        }
                        return item
                    })
                    return item
                })
                this.bwvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableOptions.length != 0) {
                            item.variableOptions.map((val) => {
                                if (val.variableName == items.pid && item.upIotUrl==topic) {
                                    val.variableValue = items.v
                                }
                                return val
                            })
                        }
                        return item
                    })
                    return item
                })
                this.tfvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableOptions.length != 0) {
                            item.variableOptions.map((val) => {
                                if (val.variableName == items.pid&& item.upIotUrl==topic) {
                                    val.variableValue = items.v
                                }
                                return val
                            })
                        }
                        return item
                    })
                    return item
                })
                this.nwzyvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableOptions.length != 0) {
                            item.variableOptions.map((val) => {
                                if (val.variableName == items.pid && item.upIotUrl==topic) {
                                    val.variableValue = items.v
                                }
                                return val
                            })
                        }
                        return item
                    })
                    return item
                })
                this.fjzyvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableName == items.pid && item.upIotUrl==topic) {
                            item.variableValue = Number(items.v) 
                        }
                        return item
                    })
                    return item
                })
                this.slzyvariableList.map((item, index) => {
                    JSON.parse(this.message).d.map((items, indexs) => {
                        if (item.variableName == items.pid && item.upIotUrl==topic) {
                            item.variableValue = Number(items.v) 
                        }
                        return item
                    })
                    return item
                })
                setTimeout(() => {
                    this.dialogVisiblejiazaiz = false;
                }, 1000)
            });
          this.client[upIotUrl] = client;
        },
      // 温室控制
      controlItemListApi(farmId,greenHouseId,auths){
        let that = this;
        controlItemList(farmId,greenHouseId,auths).then(res=>{
          if (res.code==500) {
            this.$message(res.message);
            this.loading = false
            return
          }
          if (res.code==200) {
            this.loading = false
          }
          this.controlItemListNmuber = res.result
          if (res.result!='') {
            this.zmvariableList = (res.result[0].variableList||[]).map(el=>{ el.variableValue=Number(el.variableValue);return el})  //照明系统
            if (this.wsimg == 'shws') {
              this.bwvariableList = res.result[0].variableList  //保温系统
            } else {
              this.bwvariableList = res.result[1].variableList  //保温系统
            }
            setTimeout(() => {
                this.bwvariableList.map((val, index) => {
                    if (val.variableOptions[0].variableValue == '0' && val.variableOptions[1].variableValue == '0' && val.variableOptions[2].variableValue == '0') {
                        this.bwvariableList[index].variableOptions[2].variableValue = 1;
                    }
                })
            }, 1000)
            if (this.wsimg == 'shws') {
              this.tfvariableList = res.result[1].variableList  //通风系统
            } else {
              this.tfvariableList = res.result[2].variableList  //通风系统
            }
            console.log("this.tfvariableListthis.tfvariableList",this.tfvariableList)
            setTimeout(() => {
                this.tfvariableList.map((val, index) => {
                    if (val.variableOptions[0].variableValue == '0' && val.variableOptions[1].variableValue == '0' && val.variableOptions[2].variableValue == '0') {
                        this.tfvariableList[index].variableOptions[2].variableValue = 1;
                    }
                })
            }, 1000)
            if (this.wsimg == 'shws') {
              this.nwzyvariableList = res.result[2].subVariable[0].variableList //内外遮阳
              this.fjzyvariableList =(res.result[2].subVariable[1].variableList||[]).map(el=>{ el.variableValue=Number(el.variableValue);return el}) //风机/内循环
              this.slzyvariableList =  (res.result[2].subVariable[2].variableList||[]).map(el=>{ el.variableValue=Number(el.variableValue);return el}) //湿帘
            } else {
              this.nwzyvariableList = res.result[3].subVariable[0].variableList //内外遮阳
              this.fjzyvariableList =(res.result[3].subVariable[1].variableList||[]).map(el=>{ el.variableValue=Number(el.variableValue);return el}) //风机/内循环
              this.slzyvariableList =  (res.result[3].subVariable[2].variableList||[]).map(el=>{ el.variableValue=Number(el.variableValue);return el}) //湿帘
            }
            
            setTimeout(() => {
                this.nwzyvariableList.map((val, index) => {
                    if (val.variableOptions[0].variableValue == '0' && val.variableOptions[1].variableValue == '0' && val.variableOptions[2].variableValue == '0') {
                        this.nwzyvariableList[index].variableOptions[2].variableValue = 1;
                    }
                })
            }, 1000)
            // mqtt开始
            // let oneArr = []
            let takeArr = []
            let allUpIotUrl = new Set();
            let allDownIotUrl = new Set();
            let oneArr = new Object();
            // this.downIotUrl = this.zmvariableList[0].downIotUrl
            this.zmvariableList.map((item, index) => {
                let valobjs = {
                    "pid": item.variableName,
                    "sid": "ModbusTCP",
                    "downIotUrl": item.downIotUrl,
                    "upIotUrl": item.upIotUrl
                }
                // oneArr.push(valobjs)
                if(!oneArr[item.upIotUrl]){
                  oneArr[item.upIotUrl] = [];
                  oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                }
                oneArr[item.upIotUrl].push(valobjs);

                allUpIotUrl.add(item.upIotUrl)
                allDownIotUrl.add(item.downIotUrl);
            })
            this.bwvariableList.map((item, index) => {
                if (item.variableOptions.length != 0) {
                    item.variableOptions.map((val) => {
                        takeArr.push(val)
                        let valobjs = {
                            "pid": val.variableName,
                            "sid": "ModbusTCP",
                            "downIotUrl": item.downIotUrl,
                            "upIotUrl": item.upIotUrl
                        }
                         if(!oneArr[item.upIotUrl]){
                          oneArr[item.upIotUrl] = [];
                          oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                        }
                        oneArr[item.upIotUrl].push(valobjs);
                        allUpIotUrl.add(item.upIotUrl)
                        allDownIotUrl.add(item.downIotUrl);
                    })
                }
            })
            this.tfvariableList.map((item, index) => {
                if (item.variableOptions.length != 0) {
                    item.variableOptions.map((val) => {
                        takeArr.push(val)
                        let valobjs = {
                            "pid": val.variableName,
                            "sid": "ModbusTCP",
                            "downIotUrl": item.downIotUrl,
                            "upIotUrl": item.upIotUrl
                        }
                        // oneArr.push(valobjs)
                        if(!oneArr[item.upIotUrl]){
                          oneArr[item.upIotUrl] = [];
                          oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                        }
                        oneArr[item.upIotUrl].push(valobjs);
                        allUpIotUrl.add(item.upIotUrl)
                        allDownIotUrl.add(item.downIotUrl);
                    })
                }
                
            })
            this.nwzyvariableList.map((item, index) => {
                
                
                if (item.variableOptions.length != 0) {
                    item.variableOptions.map((val) => {
                        takeArr.push(val)
                        let valobjs = {
                            "pid": val.variableName,
                            "sid": "ModbusTCP",
                            "downIotUrl": item.downIotUrl,
                            "upIotUrl": item.upIotUrl
                        }
                        // oneArr.push(valobjs)
                        if(!oneArr[item.upIotUrl]){
                          oneArr[item.upIotUrl] = [];
                          oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                        }
                        oneArr[item.upIotUrl].push(valobjs);
                    })
                }
                allUpIotUrl.add(item.upIotUrl)
                allDownIotUrl.add(item.downIotUrl);
            })
            takeArr.map((item) => {
                let valobjs = {
                    "pid": item.variableName,
                    "sid": "ModbusTCP",
                    "downIotUrl": item.downIotUrl,
                    "upIotUrl": item.upIotUrl
                }
                // oneArr.push(valobjs)
                if(!oneArr[item.upIotUrl]){
                  oneArr[item.upIotUrl] = [];
                  oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                }
                oneArr[item.upIotUrl].push(valobjs);
                
                allUpIotUrl.add(item.upIotUrl)
                allDownIotUrl.add(item.downIotUrl);
            })
            this.fjzyvariableList.map((item, index) => {
                let valobjs = {
                    "pid": item.variableName,
                    "sid": "ModbusTCP",
                    "downIotUrl": item.downIotUrl,
                    "upIotUrl": item.upIotUrl
                }
                // oneArr.push(valobjs)
                if(!oneArr[item.upIotUrl]){
                  oneArr[item.upIotUrl] = [];
                  oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                }
                oneArr[item.upIotUrl].push(valobjs);
                
                allUpIotUrl.add(item.upIotUrl)
                allDownIotUrl.add(item.downIotUrl);
            })
            this.slzyvariableList.map((item, index) => {
                let valobjs = {
                    "pid": item.variableName,
                    "sid": "ModbusTCP",
                    "downIotUrl": item.downIotUrl,
                    "upIotUrl": item.upIotUrl
                }
                // oneArr.push(valobjs)
                if(!oneArr[item.upIotUrl]){
                  oneArr[item.upIotUrl] = [];
                  oneArr[item.upIotUrl+"_downIotUrl"] = item.downIotUrl;
                }
                oneArr[item.upIotUrl].push(valobjs);
                
                allUpIotUrl.add(item.upIotUrl)
                allDownIotUrl.add(item.downIotUrl);
            })
            function chunkArray(array, chunkSize) {
                let result = [];
                for (let i = 0; i < array.length; i += chunkSize) {
                    result.push(array.slice(i, i + chunkSize));
                }
                return result;
            }
            allUpIotUrl.forEach(function(item){
              if(item){
                that.connectMqtt(item);
              }
            });
      
            function findVar(item2, index){
                let obj = {
                    "f": "q",
                    "d": item2
                } 
                if(that.client[index]){
                  that.client[index].publish(oneArr[index+"_downIotUrl"], JSON.stringify(obj));
                }
            }
            Object.keys(oneArr).forEach(index => {
              if(index == 'undefined'){return;}
              const item = oneArr[index];
              if(Array.isArray(item)){
                const array = [...item];
                const chunkedArray = chunkArray(array, 20);
                chunkedArray.map((item2) => {
                    setTimeout(findVar(item2, index), 2000)
                })
              }
            });
          }
        })
      },
      deviceSurvDataApi(farmId,greenHouseId,auths){
        deviceSurvData(farmId,greenHouseId,auths).then(res=>{
          this.deviceSurvDataList = res.result
        })
      },
      deviceOprateLogApi(e,id){
        this.jkvariableId = e
        this.deployId = id
        deviceOprateLog(this.auths,this.deployId,this.jkvariableId,this.jkpagesize,this.jkcurrentPage).then(res=>{
          this.cztableData = res.result.records
          this.totalczjl = res.result.total
        })
      },
      handleCurrentChange(currentPage){
        console.log(currentPage,'currentPage');
        this.jkpagesize = currentPage;
        deviceOprateLog(this.auths,this.deployId,this.jkvariableId,this.jkpagesize,this.jkcurrentPage).then(res=>{
          this.cztableData = res.result.records
          this.totalczjl = res.result.total
        })
      },
      // 历史数据曲线
      deviceSummaryApi(){
        let obj = {
          deployId:this.deployId,
          // endTime:this.endTime,
          startTime:this.startTime,
          survItem:this.itemValue,
          auths:this.auths,
          summrayMode: this.summrayMode,
        }
        deviceSummary(obj).then(res=>{
          let that = this
          this.dataListVlaue = res.result.dataList
          this.timeListVlaue = res.result.timeList
          this.formListVlaue = res.result.formList
          this.plantAdviceVlaue = res.result.plantAdvice
          this.envAdviceVlaue = res.result.envAdvice
          this.unitValue = res.result.itemInfo.unit
          this.limitStrValue = res.result.itemInfo.limitStr
          this.descriptionValue = res.result.itemInfo.description
          if (res.code==200) {
            setTimeout(() => {
              that.$nextTick(() => {
                that.drawChart(res.result);
              })
            }, 1000);
            that.trbgzhuanq = false
          }
        })
      },
      shujufenxi(val){
        let data = Object.keys(val)
        let str = ''
        for (let index = 0; index < data.length; index++) {
          str += `<div style="text-indent:2em;">${data[index]}:${val[data[index]]}</div>`
        }
        return str;
      },
      // 温室列表
      greenHouseApi(farmId,auths){
        let hdghd = []
        greenHouse(farmId,auths).then(res=>{
           hdghd[0] = res.result.slice(0,1);
           hdghd[1] = res.result.slice(2,3);
            this.greenHouseList = [...hdghd[0],...hdghd[1]]
          this.greenHouseId = res.result[0].id
          this.gardenBelowApi(this.greenHouseId)
          // this.camListApi(this.greenHouseId)
          if (res.code=='200') {
            this.gardenLeftApi(this.farmId,this.auths,this.greenHouseId)
          }
          
        })
      },
       // 园区信息
      gardenLeftApi(farmId,auths,greenHouseId){
        gardenLeft(farmId,auths,greenHouseId).then(res=>{
          this.farminfo = res.result.farminfo
          this.farmAcre = res.result.farminfo.farmAcre
          this.predictOutput = res.result.farminfo.predictOutput
          this.predictProfit = res.result.farminfo.predictProfit
          this.listData = res.result.farminfo.farmDesc.split(',') //字符串按逗号分隔成数组
          this.zpmslist = res.result.plantInfos
        })
      },
      // 智能设备_监测数据_重点监控
      gardenBelowApi(e){
        let farmId = this.farmId
        let auths = this.auths
        if (this.greenHouseId) {
          this.greenHouseIdnum = this.greenHouseId
        } else {
          this.greenHouseIdnum = e
        }
        gardenBelow(farmId,this.greenHouseIdnum,auths).then(res=>{
            this.gardenBelowList = res.result
            this.soilDataList = res.result.soilData
            // this.airDataListHome = res.result.airData
            this.airDataListHome = [...res.result.soilData, ...res.result.airData];
            if (res.result.deviceCate!='') {
              // 智能设备
              this.airdeviceCount = res.result.deviceCateMap.air
              this.cameraCount = res.result.deviceCateMap.camera
              this.soildeviceCount = res.result.deviceCateMap.soil
              this.waterCount = res.result.deviceCateMap["6_water"]
              this.deviceCount = res.result.deviceCateMap.control_cab
              this.sfytdeviceCount = res.result.deviceCateMap.integrated_machine
              // 监控
              this.deviceUrl = res.result.camra.deviceUrl
              this.deploySecondaryType = res.result.camra.deploySecondaryType
              this.deployCodeId = res.result.camra.deployCode
              this.ysToken = res.result.camra.ysToken
              this.deviceIotUrl = res.result.camra.deviceIotUrl
              setTimeout(() =>{
                this.player = new EZUIKit.EZUIKitPlayer({
                  id: "deployCodevideoid", // 视频容器ID
                  accessToken:this.ysToken,
                  url: this.deviceIotUrl,
                  template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                  audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                  muted: false,
                  autoplay: true,
                  width:456,
                  height:270,
                  handleSuccess: () => {
                    setTimeout(() => {
                      this.player && this.player.closeSound()
                    }, 100)
                    // console.log("播放成功回调函数，此处可执行播放成功后续动作")
                  },
                })
              }, 300)
              // this.deploySecondaryType = res.result.camra.deploySecondaryType
            }
          })
      },
      // 公共-摄像头列表(视频详情)
      camListApi(e){
        let farmId = this.farmId
        let auths = this.auths
        if (this.greenHouseId) {
          this.greenHouseIdnum = this.greenHouseId
        } else {
          this.greenHouseIdnum = e
        }
        let pageNo = this.pageNo
        let pageSize = this.pageSize
        camSurvPage(farmId,this.greenHouseIdnum,auths,pageNo,pageSize).then(res=>{
          this.homeVideoList = res.result.records
          this.total = res.result.total
          // res.result.records.forEach((val)=>{
          //   setTimeout(() =>{
          //     var player = new EZUIKit.EZUIKitPlayer({
          //       id: val.deployCode, // 视频容器ID
          //       accessToken: val.ysToken,
          //       url: val.deviceIotUrl,
          //       template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          //       audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
          //       autoplay: true,
          //       width:430,
          //       height:270,
          //     })
          //     this.player = player
          //   }, 300)
          // })
        })
      },
      handleCurrentfxChange(val) {
        this.pageNo = val
        let farmId = this.farmId
        let auths = this.auths
        if (this.greenHouseId) {
          this.greenHouseIdnum = this.greenHouseId
        } else {
          this.greenHouseIdnum = e
        }
        let pageNo = this.pageNo
        let pageSize = this.pageSize
        camSurvPage(farmId,this.greenHouseIdnum,auths,pageNo,pageSize).then(res=>{
          this.homeVideoList = res.result.records
          this.total = res.result.total
          // res.result.records.forEach((val)=>{
          //   setTimeout(() =>{
          //     var player = new EZUIKit.EZUIKitPlayer({
          //       id: val.deployCode, // 视频容器ID
          //       accessToken: val.ysToken,
          //       url: val.deviceIotUrl,
          //       template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          //       audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
          //       autoplay: true,
          //       width:430,
          //       height:270,
          //     })
          //     this.player = player
          //   }, 300)
          // })
        })
      },
      // 时间开始
      getNowTime() {
        let speed = 1000;
        let that = this;
        let theNowTime = function () {
          that.nowTime = that.timeNumber();
        };
        setInterval(theNowTime, speed);
      },
      timeNumber() {
        let today = new Date();
        let date =
          today.getFullYear() +
          "年" +
          this.twoDigits(today.getMonth() + 1) +
          "月" +
          this.twoDigits(today.getDate()) +
          "日";
        let time =
          this.twoDigits(today.getHours()) +
          ":" +
          this.twoDigits(today.getMinutes()) +
          ":" +
          this.twoDigits(today.getSeconds());
        let wk = new Date().getDay();
        let weeks = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        let week = weeks[wk];
        return date + "  " + week + "  " + time;
      },
      twoDigits(val) {
        if (val < 10) return "0" + val;
        return val;
      },
        // 时间结束
      zdinfor() {
        this.qczddialogTableVisible = true;
      },
      zpmsfor() {
        this.leftPlantApi(this.farmId,this.greenHouseId,this.auths,this.pageNo,this.pageSize )
        this.zpmsdialogTableVisible = true;
      },
      sxtfor() {
        this.pageNo = 1
        this.camListApi(this.farmId,this.greenHouseId,this.auths)
        this.sxtdialogTableVisible = true;
      },
       jcsjfor() {
         if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
        this.deviceSurvDataApi(this.farmId,this.greenHouseIdnum,this.auths )
        this.jcsjdialogTableVisible = true;
      },
      wskzgfor(){
        this.homelist = 'wskz'
        if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          this.controlItemListApi(this.farmId,this.greenHouseIdnum,this.auths);
      },
      // 水肥一体化
      IrrigateFastStatusApi(auths,greenHouseId,homesf){
        this.loading=true
        IrrigateFastStatus(auths,greenHouseId).then(res=>{
            this.gropList = res.result
            this.level1List = this.gropList.level1[0].pval
            this.bsfList = this.gropList.level1[1]
            this.bsbList = this.gropList.level1[2]
            this.sdfList = this.gropList.level1[3]
            this.sfbList = this.gropList.level1[4]
            this.ggfList = this.gropList.level1[5]
            this.runStatusOverList = this.gropList.runStatus
            this.level2List = this.gropList.level2
            this.level3List = this.gropList.level3
            this.level4List = this.gropList.level4
            // mqtt接口
            this.downIotUrlsf = this.gropList.downIotUrl
            let oneArr = []
                this.gropList.level1.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                this.gropList.level2.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                this.gropList.level3.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                this.gropList.level4.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                this.gropList.mainStop.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                this.gropList.runStatus.map((item, index) => {
                    let valobjs = {
                        "pid": item.pid,
                        "sid": "ModbusTCP",
                    }
                    oneArr.push(valobjs)
                })
                function chunkArray(array, chunkSize) {
                    let result = [];
                    for (let i = 0; i < array.length; i += chunkSize) {
                        result.push(array.slice(i, i + chunkSize));
                    }
                    return result;
                }
                const array = [...oneArr];
                const chunkedArray = chunkArray(array, 10);
                chunkedArray.map((item) => {
                    setTimeout(() => {
                        let obj = {
                            "f": "q",
                            "d": item
                        }
                        this.clientSf.publish(this.downIotUrlsf, JSON.stringify(obj));
                    }, 2000)
                })
                this.connectMqttSf(this.gropList.upIotUrl,homesf)
        })
      },
      connectMqttSf(upIotUrl,homesf) {
        this.options.clientId = Math.random().toString(16).substr(2, 8)
          this.clientSf = mqtt.connect('wss://mqtt.byunsz.com/mqtt', this.options);
          this.clientSf.on('connect', () => {
              this.clientSf.subscribe(upIotUrl);
          });
          this.clientSf.on('disconnect', (req) => { })
          this.clientSf.on('message', (topic, message) => {
              this.messageSf = message.toString();
              this.gropList.level1.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.level2.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.level3.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.level4.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.mainStop.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.runStatus.map((item, index) => {
                  JSON.parse(this.messageSf).d.map((items, indexs) => {
                      if (item.pid == items.pid) {
                          item.pval = items.v
                      }
                      return item
                  })
                  return item
              })
              this.gropList.level1.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              this.gropList.level2.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              this.gropList.level3.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              this.gropList.level4.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              this.gropList.mainStop.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              this.gropList.runStatus.map((item)=>{
                  if(item.pval==undefined){
                      item.pval = '0'
                  }
              })
              if (homesf) {
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.drawChartsfythTwo(this.gropList.level1[0])
                  })
                }, 2100);
              } else {
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.drawChartsfyth(this.gropList.level1[0])
                  })
                }, 2100);
              }
              this.loading=false
          });
      },
      sfythBox() {
        this.sfythdialogTableVisible = true;
        this.$nextTick(() => {
          if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          this.IrrigateFastStatusApi(this.auths,this.greenHouseIdnum,'homesf');
        })
      },
      drawChartsfyth(valnum) {
            // // 基于准备好的dom，初始化echarts实例
            let chartTwo = this.$echarts.init(document.getElementById("chartsfyth"));
            if (chartTwo == null) {
              chartTwo = echarts.init(document.getElementById("chartsfyth"));
            }
            // 绘制图表
            chartTwo.setOption({
                series: [{
                    type: 'liquidFill',
                    data: [{
                        name: '当前液位',
                        value: Number(valnum.pval / 100) 
                    }, Number(valnum.pval / 100 + 0.01), Number(valnum.pval / 100 + 0.02)],
                    animationDuration: 1500,
                    animationDurationUpdate: 7000,
                    animationEasingUpdate: 'cubicOut',
                    radius: '167px',
                    shape: 'rect',
                    center: ['51.8%', '72.8%'],
                    outline: {
                        show: false
                    },
                    label: { //To change the text, you may use label.formatter, which can be set to a string or function.
                        normal: {
                            formatter: `${Number(valnum.pval).toFixed(2)}` + '\n{b}' , //If it is a string, {a} series name, {b} data name,{c} data value.
                            textStyle: {
                                color: 'white', //波浪上文本颜色
                                insideColor: 'yellow', //波浪内部字体颜色
                                fontSize: 12
                            },
                            // position: ['30%', '50%'], //Text position is at the center by default. label.position can be set to be 'inside', 'left', 'right', 'top', 'bottom', or horizontal and vertical positions like ['10%', '20%'], which means '10%' to the left (controlled by label.align, which can be 'left', 'center', or 'right') and '20%' to the top (controlled by label.baseline, which can be 'top', 'middle', or 'bottom').
                            // position:'top',
                            align: 'center',
                            baseline: 'center'

                        }
                    },
                    backgroundStyle: {
                        color: '#4e4f64',
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0, 0, 0,
                                1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                                [{
                                    offset: 0,
                                    color: '#30d1ed'
                                },
                                {
                                    offset: 0.5,
                                    color: '#30d1ed'
                                },
                                {
                                    offset: 1,
                                    color: '#30d1ed'
                                }
                                ] //数组, 用于配置颜色的渐变过程. 每一项为一个对象, 包含offset和color两个参数. offset的范围是0 ~ 1, 用于表示位置
                            )
                        }
                    },
                }]
            })
      },
      drawChartsfythTwo(valnum) {
            // // 基于准备好的dom，初始化echarts实例
            let chartone = this.$echarts.init(document.getElementById("chartsfythTwo"));
            if (chartone == null) {
              chartone = echarts.init(document.getElementById("chartsfythTwo"));
            }
            // 绘制图表
            chartone.setOption({
                series: [{
                    type: 'liquidFill',
                    data: [{
                        name: '当前液位',
                        value: Number(valnum.pval / 100)
                    }, Number(valnum.pval / 100 + 0.01), Number(valnum.pval / 100 + 0.02)],
                    animationDuration: 1500,
                    animationDurationUpdate: 7000,
                    animationEasingUpdate: 'cubicOut',
                    radius: '167px',
                    shape: 'rect',
                    center: ['51.8%', '72.8%'],
                    outline: {
                        show: false
                    },
                    label: { //To change the text, you may use label.formatter, which can be set to a string or function.
                        normal: {
                            formatter: `${Number(valnum.pval).toFixed(2)}` + '\n{b}', //If it is a string, {a} series name, {b} data name,{c} data value.
                            textStyle: {
                                color: 'white', //波浪上文本颜色
                                insideColor: 'yellow', //波浪内部字体颜色
                                fontSize: 12
                            },
                            // position: ['30%', '50%'], //Text position is at the center by default. label.position can be set to be 'inside', 'left', 'right', 'top', 'bottom', or horizontal and vertical positions like ['10%', '20%'], which means '10%' to the left (controlled by label.align, which can be 'left', 'center', or 'right') and '20%' to the top (controlled by label.baseline, which can be 'top', 'middle', or 'bottom').
                            // position:'top',
                            align: 'center',
                            baseline: 'center'

                        }
                    },
                    backgroundStyle: {
                        color: '#4e4f64',
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0, 0, 0,
                                1, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                                [{
                                    offset: 0,
                                    color: '#30d1ed'
                                },
                                {
                                    offset: 0.5,
                                    color: '#30d1ed'
                                },
                                {
                                    offset: 1,
                                    color: '#30d1ed'
                                }
                                ] //数组, 用于配置颜色的渐变过程. 每一项为一个对象, 包含offset和color两个参数. offset的范围是0 ~ 1, 用于表示位置
                            )
                        }
                    },
                }]
            })
      },
      zpshow(){
        this.zpmsdialogTableVisible = false;
        this.zpshowdialogTableVisible = true
      },
      sxtshow(e){
        this.inoutCamList = []
        this.landCamList = []
        this.loading = true
        this.sxtshowdialogTableVisible = true;
        this.itemdeployCode = e.deployCode
        this.showSeployDes = e.deployDes
        this.sxtdeploySecondaryType = e.deploySecondaryType
        if (this.showSeployDes) {
          setTimeout(() =>{
            this.playerTc = new EZUIKit.EZUIKitPlayer({
              id: e.deployCode, // 视频容器ID
              accessToken: e.ysToken,
              url: e.deviceIotUrl,
              template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
              audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
              autoplay: true,
              width:1800,
              height:1010,
              handleSuccess: () => {
                setTimeout(() => {
                  this.player && this.player.closeSound()
                }, 100)
                // console.log("播放成功回调函数，此处可执行播放成功后续动作")
              },
            })
          }, 300)
          setTimeout(() =>{
            this.loading = false
          }, 2000)
        }
      },
      sxtcz(cameraId,number){
        let obj = {
          cameraId:cameraId,
          number:number,
          auths:this.auths,
        }
        cameraStart(obj).then(res=>{
          if (res.code==200) {
            setTimeout(()=> {
                cameraStop(obj).then(val=>{
                  })
            }, 500);
          }
        })
      },
      // 监测数据弹窗
      trwdgdtc(e){
        const lisst = e.target.closest(".tcgd2"); // 定位元素
        if (lisst) { // 是否是滚动组件的某一行/列
          let itemlist  = lisst.dataset.item
          this.trwdtc(JSON.parse(itemlist))
        }
      },
      trwdtc(value){
        // 弹窗重新刷新
        this.radiotr =  '1',
        this.activeTime = 'first',
        this.summrayMode = 'dayhours'
        this.startTime = ''
        // 弹窗重新刷新结束
        this.deployId = value.deployId
        this.itemValue = value.item
         this.trwdNmae = value.name
        this.trwddialogTableVisible = true;
        this.trbgzhuanq = true
        setTimeout(() =>{
          this.deviceSummaryApi()
          this.trbg = true;
        }, 100)
      },
      jcsjwdtc(value){
        // 弹窗重新刷新
        this.radiotr =  '1',
        this.activeTime = 'first',
        this.summrayMode = 'dayhours'
        this.startTime = ''
        // 弹窗重新刷新结束
        this.deployId = value.deployId
        this.itemValue = value.item
         this.trwdNmae = value.name
        this.jcsjwddialogTableVisible = true;
        this.trbgzhuanq = true
        this.deviceSummaryApi()
        setTimeout(() =>{
          this.trbg = true;
        }, 500)
      },
      timetrpicker(data){
        this.startTime = data.displayValue
      },
      tanchuangshuj(){
        this.trbgzhuanq = true
        this.deviceSummaryApi()
      },
      timetcxuan(e){
        this.trbgzhuanq = true
        if (e.index=='0') {
          this.summrayMode = 'dayhours'
          this.startTime = ''
          this.deviceSummaryApi()
        } else if(e.index=='1'){
          this.summrayMode = 'monthDays'
          this.startTime = ''
          this.deviceSummaryApi()
        }else{
          this.summrayMode = 'yearMonth'
          this.startTime = ''
          this.deviceSummaryApi()
        }
      },
      lxlztc(){
        this.lxlizpialogTableVisible = true;
      },
      wskztc(id,deployId){
        this.wskzdialogTableVisible = true;
        this.deviceOprateLogApi(id,deployId)
      },
      qcguanbi1(){
        this.sxtshowdialogTableVisible= false;
        // this.player.stop().then(() => {
        //     this.player = {}
        // })
        this.playerTc.stop().then(() => {
            this.playerTc = {}
        })
        this.camSurvListApi(this.farmId,this.greenHouseIdnum,this.auths )
      },
      // 积温积雨
      jwjytjtc(){
        this.jwjydialogTableVisible = true
        setTimeout(() => {
          this.jjwjyChart()
        }, 500);
      },
       // 积温积雨选择
      jwjyChange(val){
        this.jwtjNum = val
        let that = this 
        if (val=='1') {
          setTimeout(() =>{
            that.$nextTick(() => {
              that.jjwjyChart()
            })
          }, 100)
        } else if(val=='2'){
          setTimeout(() =>{
            that.jjyjyChart()
          }, 100)
        }
      },
      // 积温曲线
      jjwjyChart(val) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChartjw = this.$echarts.init(document.getElementById("jiwennum"));
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            show:true,
            trigger: 'axis',
            backgroundColor: "rgba(13, 64, 71, 0.50)",
            borderColor: "rgba(143, 225, 252, 0.60)",
            padding: 8,
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#3c6ae1'
              }
            },
            formatter: (params) => {
              console.log("00000",params);
              var relVal = '月份：'+ params[0].name +'月'
              for (var i = 0, l = params.length; i < l; i++) {
                relVal += '<br/>' + params[i].seriesName + ' ' + params[i].marker + ' ' + params[i].value + '℃'
              }
              return relVal
            },
          },
          legend: {
            data: ['']
          },
          toolbox: {
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: 'rgba(85,111,253,0.70)',
                },
              {
                offset: 1,
                color: 'rgba(85,111,253,0.20)',
                  },
              ],
              global: false,
              },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: [1,2,3,4,5,6,7,8,9,10,11,12],
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          series: [
            {
              name: '积温',
              animationDurationUpdate: 1000, // 更新动画时长为 1s
              animationEasingUpdate: 'quinticInOut', // 更新动画缓动效果为 'quinticInOut'
              type: 'line',
              stack: 'Total',
              smooth:true,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: '#3c6ae1' // 设置线的颜色为红色
              },
              markPoint: {
                  data: [
                      {type: 'max', name: '最大值'},
                      {type: 'min', name: '最小值'}
                  ]
              },
              markLine: {
                  data: [
                      {
                        type: 'average', 
                        name: '平均值',
                        label: {
                          position: 'end', // 文字位置
                          color: '#fff'  // 文字颜色
                        },
                         lineStyle: { type: 'dashed', color: '#00FFCD', width: 2 }
                      }
                  ]
              },
              data: [1.6,4.5,10.2,18.5,24.1,27.7,28.4,26.6,22.7,17.1,9.5,3.2] ,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartjw.clear();
        myChartjw.setOption(option);
      },
      // 积雨曲线
      jjyjyChart(val) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChartjw = this.$echarts.init(document.getElementById("jiyunum"));
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            show:true,
            trigger: 'axis',
            backgroundColor: "rgba(13, 64, 71, 0.50)",
            borderColor: "rgba(143, 225, 252, 0.60)",
            padding: 8,
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#3c6ae1'
              }
            },
            formatter: (params) => {
              console.log(params);
              var relVal = '月份：'+ params[0].name + '月'
              for (var i = 0, l = params.length; i < l; i++) {
                relVal += '<br/>' + params[i].seriesName + ' ' + params[i].marker + ' ' + params[i].value + 'mm'
              }
              return relVal
            },
          },
          legend: {
            data: ['']
          },
          toolbox: {
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: 'rgba(85,111,253,0.70)',
                },
              {
                offset: 1,
                color: 'rgba(85,111,253,0.20)',
                  },
              ],
              global: false,
              },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: [1,2,3,4,5,6,7,8,9,10,11,12],
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          series: [
            {
              name: '积雨',
              animationDurationUpdate: 1000, // 更新动画时长为 1s
              animationEasingUpdate: 'quinticInOut', // 更新动画缓动效果为 'quinticInOut'
              type: 'line',
              stack: 'Total',
              smooth:true,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: '#3c6ae1' // 设置线的颜色为红色
              },
              markPoint: {
                  data: [
                      {type: 'max', name: '最大值'},
                      {type: 'min', name: '最小值'}
                  ]
              },
              markLine: {
                  data: [
                      {
                        type: 'average', 
                        name: '平均值',
                        label: {
                          position: 'end', // 文字位置
                          color: '#fff'  // 文字颜色
                        },
                         lineStyle: { type: 'dashed', color: '#00FFCD', width: 2 }
                      }
                  ]
              },
              data: [3.7,5.5,13,21.6,46.1,76.1,122.7,124.2,71.7,26.1,11.8,2.8] ,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartjw.clear();
        myChartjw.setOption(option);
      },
      qcguanbi() {
        this.qczddialogTableVisible = false;
        this.zpmsdialogTableVisible = false;
        this.sxtdialogTableVisible = false;
        this.jcsjdialogTableVisible = false;
        this.zpshowdialogTableVisible = false;
        this.trwddialogTableVisible = false;
        this.lxlizpialogTableVisible = false;
        this.wskzdialogTableVisible = false;
        this.sfythdialogTableVisible = false;
        this.jwjydialogTableVisible = false
      },
      jcsjguanbi(){
        this.jcsjwddialogTableVisible = false;
      },
      // 展示方式选择
      agreeChange(val){
        let that = this 
        this.trbg = false;
        if (val=='1') {
          this.trbg = true
          this.trbgzhuanq = true
          this.trzx = false
          setTimeout(() =>{
            that.$nextTick(() => {
              that.deviceSummaryApi()
            })
          }, 100)
        } else if(val=='2'){
          this.trbgzhuanq = true
          setTimeout(() =>{
            that.deviceSummaryApi()
            this.trzx = true
            this.trbg = false
          }, 100)
        }
        
        that.btnstatus=(val==='1')?true:false;
      },
      // 监测数据曲线
      drawChart(val) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart1 = this.$echarts.init(document.getElementById("main"));
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            show:true,
            trigger: 'axis',
            backgroundColor: "rgba(13, 64, 71, 0.50)",
            borderColor: "rgba(143, 225, 252, 0.60)",
            padding: 8,
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#3c6ae1'
              }
            },
            formatter: (params) => {
              console.log(params);
              var relVal = '日期：'+ params[0].name
              for (var i = 0, l = params.length; i < l; i++) {
                relVal += '<br/>' + params[i].seriesName + ' ' + params[i].marker + ' ' + params[i].value + val.itemInfo.unit
              }
              return relVal
            },
          },
          legend: {
            data: ['']
          },
          toolbox: {
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [  // 渐变颜色
              {
                offset: 0,
                color: 'rgba(85,111,253,0.70)',
                },
              {
                offset: 1,
                color: 'rgba(85,111,253,0.20)',
                  },
              ],
              global: false,
              },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: val.timeList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                //x轴文字的配置
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
            }
          ],
          series: [
            {
              name: val.itemInfo.description,
              animationDurationUpdate: 1000, // 更新动画时长为 1s
              animationEasingUpdate: 'quinticInOut', // 更新动画缓动效果为 'quinticInOut'
              type: 'line',
              stack: 'Total',
              smooth:true,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: '#3c6ae1' // 设置线的颜色为红色
              },
              markPoint: {
                  data: [
                      {type: 'max', name: '最大值'},
                      {type: 'min', name: '最小值'}
                  ]
              },
              markLine: {
                  data: [
                      {
                        type: 'average', 
                        name: '平均值',
                        label: {
                          position: 'end', // 文字位置
                          color: '#fff'  // 文字颜色
                        },
                         lineStyle: { type: 'dashed', color: '#00FFCD', width: 2 }
                      }
                  ]
              },
              data: val.dataList ,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart1.clear();
        myChart1.setOption(option);
        // 自动切换
        // var index = 0; 
				// var mTime = setInterval(function() {
				// 	myChart1.dispatchAction({
				// 		type: 'showTip',
				// 		seriesIndex: 0,
				// 		dataIndex: index
				// 	});
				// 	index++;
				// 	if(index > val.dataList.length) {
				// 		index = 0;
				// 	}
				// }, 2000);
      },
      // 菜单切换
      handleClick(tab,event){
        this.tabIndex = tab.index
        if (tab.label=='园区概览') {
          this.homelist = 'yqgl'
        } else if(tab.label=='环境监测'){
          this.homelist = 'hjjc'
           if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          this.envSurvDataApi(this.farmId,this.greenHouseIdnum,this.auths )
          this.alertListApi(this.auths)
          this.IrrigateFastStatusApi(this.auths,this.greenHouseIdnum);
        } else if(tab.label=='温室控制'){
          this.homelist = 'wskz'
          if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          this.controlItemListApi(this.farmId,this.greenHouseIdnum,this.auths);
        } else if(tab.label=='视频监控'){
          this.homelist = 'spjk'
           if (this.greenHouseId) {
            this.greenHouseIdnum = this.greenHouseId
          } else {
            this.greenHouseIdnum = ''
          }
          setTimeout(() =>{
            this.$nextTick(()=>{
              this.camSurvListApi(this.farmId,this.greenHouseIdnum,this.auths )
            })
          }, 300)
        }
      },
      overclose(){
        this.homelist ='';
        this.tabListShow = !this.tabListShow
        if (this.tabListShow) {
          this.homelist ='yqgl';
          this.activeName = 'first'
        }
      },
      close() {
             this.playerleft.map(ele=>{
              ele.stop().then(()=>{
                ele = {}
              })
            })
            this.playerRight.map(ele=>{
              ele.stop().then(()=>{
                ele = {}
              })
            })
            setTimeout(() => {
              this.playerleft = []
              this.playerRight = []
            }, 0);
        },
      // 菜单切换
      handleClickwslif(tab,event){
          // if (this.player) {
          //   this.player.stop();
          //   this.player.destroy();
          //   this.player = null;
          // }
          this.close()
        if (tab.label=="一号温室") {
          this.wsimg ='yhws'
          this.greenHouseId = tab.$attrs.id
          this.gardenLeftApi(this.farmId,this.auths,this.greenHouseId)
          this.gardenBelowApi(this.farmId,this.greenHouseId,this.auths )
          this.camListApi(this.farmId,this.greenHouseId,this.auths )
          this.controlItemListApi(this.farmId,this.greenHouseId,this.auths )
          this.envSurvDataApi(this.farmId,this.greenHouseId,this.auths )
          this.camSurvListApi(this.farmId,this.greenHouseId,this.auths  )
        } else if(tab.label==="二号温室"){
          this.wsimg = 'ehws'
          this.greenHouseId = tab.$attrs.id
          this.gardenLeftApi(this.farmId,this.auths,this.greenHouseId)
          this.gardenBelowApi(this.farmId,this.greenHouseId,this.auths )
          this.camListApi(this.farmId,this.greenHouseId,this.auths )
          this.controlItemListApi(this.farmId,this.greenHouseId,this.auths )
          this.envSurvDataApi(this.farmId,this.greenHouseId,this.auths )
          this.camSurvListApi(this.farmId,this.greenHouseId,this.auths  )
        } else {
          this.wsimg = 'shws'
          this.greenHouseId = tab.$attrs.id
          this.gardenLeftApi(this.farmId,this.auths,this.greenHouseId)
          this.gardenBelowApi(this.farmId,this.greenHouseId,this.auths )
          this.camListApi(this.farmId,this.greenHouseId,this.auths )
          this.controlItemListApi(this.farmId,this.greenHouseId,this.auths )
          this.envSurvDataApi(this.farmId,this.greenHouseId,this.auths )
          this.camSurvListApi(this.farmId,this.greenHouseId,this.auths)
        }
      },
      // initVideo() {
      //   this.dp = Videojs('videoPlayer', this.optionsVideo);
      // },
      formatter(num) {
        if (num) {
          return num.toFixed(1)
        }
        
      },
      startAnimate() {
        if (this.$refs.myNum1[0]) {
          this.$nextTick(() => {
              this.$refs.myNum1.map(res=>{
                res.start()
              })
              if (this.$refs.myNumhj1) {
                this.$refs.myNumhj1.map(res=>{
                  res.start()
                })
              }
              if (this.$refs.myNumtj1) {
                // this.$refs.myNumtj1[0].start()
                this.$refs.myNumtj1.map(res=>{
                  res.start()
                })
              }
              
          })
        }
      },
       // 间隔特定时间检测系统无操作时间
      onload() {
        this.timer = window.setInterval(this.checkTimeOut, this.check_time)
      },
      // 判断系统无操作时间是否大于安全时间
      checkTimeOut() {
        this.currentTime = new Date().getTime();
        if(this.currentTime - this.lastTime > this.sys_timeout) {
               //超时退出系统
          let tabList = ['first','second','third','fourth']
          let active = 0
          setInterval(() => {
            active++
            if (active>3) {
              active = 0
            }
            this.activeName = tabList[active]
            if (this.activeName=='first') {
                this.homelist = 'yqgl'
              } else if(this.activeName=='second'){
                this.homelist = 'hjjc'
                if (this.greenHouseId) {
                  this.greenHouseIdnum = this.greenHouseId
                } else {
                  this.greenHouseIdnum = ''
                }
                this.envSurvDataApi(this.farmId,this.greenHouseIdnum,this.auths )
                this.alertListApi(this.auths)
              } else if(this.activeName=='third'){
                this.homelist = 'wskz'
                if (this.greenHouseId) {
                  this.greenHouseIdnum = this.greenHouseId
                } else {
                  this.greenHouseIdnum = ''
                }
                this.controlItemListApi(this.farmId,this.greenHouseIdnum,this.auths);
              } else if(this.activeName=='fourth'){
                this.homelist = 'spjk'
                if (this.greenHouseId) {
                  this.greenHouseIdnum = this.greenHouseId
                } else {
                  this.greenHouseIdnum = ''
                }
                setTimeout(() =>{
                  this.$nextTick(()=>{
                    this.camSurvListApi(this.farmId,this.greenHouseIdnum,this.auths )
                  })
                }, 300)
              }
          }, 10000);
        }
      }
    },
    beforeDestroy(){
      clearInterval(this.reloadInterval)
    }
  }
</script>

<style  scoped>
@import "@/assets/css/index.css";

</style>
 
 <style lang="less">
 .able_btns {
    // width: 112px;
    background:none;
    display: flex;
    justify-content: space-between;
    border: 1px solid #eee;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 2px 4px;
    box-sizing: border-box;
    width: 70%;
    margin-top: 10px;
    font-size: 14px;
    div {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    .off {
        color: #fd4f4f;
    }

    .off.active {
        color: #fff;
        background: linear-gradient(0deg, #fe4d4d, #fc886d);
        box-shadow: 0 3px 6px 0 rgba(254, 77, 77, .4)
    }

    .pause {
        color: #e37405;
    }

    .pause.active {
        color: #fff;
        background: linear-gradient(0deg, #fe944d, #febd4d);
        box-shadow: 0 3px 6px 0 rgba(254, 159, 77, .4);
    }
    .pause.actives {
        color: #fff;
        background: linear-gradient(0deg, #fe944d, #febd4d);
        box-shadow: 0 3px 6px 0 rgba(254, 159, 77, .4);
    }
    .on {
        color: #21d84f;
    }

    .on.active {
        color: #fff;
        background: linear-gradient(0deg, #1dc525, #25f084);
        box-shadow: 0 3px 6px 0 rgba(37, 223, 107, .4);
    }
}
 </style>